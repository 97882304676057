import { useCallback, useState, useMemo, useEffect } from "react";
import { useDataProvider, useNotification } from "@modir/core";
import {
  Button,
  Checkbox,
  Group,
  isNotEmpty,
  LoadingOverlay,
  Stack,
  Textarea,
  TextInput,
  useForm,
} from "@modir/ui-mantine";
import { screenService } from "@services";
import { useAccount } from "@providers/AccountContext";

export const ScreenEditor = (props: any) => {
  const { onClose, id, projectId } = props;

  const dataProvider = useDataProvider();
  const { currentOrganization } = useAccount();
  const _screenService = useMemo(
    () => screenService(dataProvider(), currentOrganization?.OrganizationId!),
    [dataProvider, currentOrganization]
  );
  const { open: notify } = useNotification();

  const form = useForm({
    validate: {
      Name: isNotEmpty("This field is required."),
      DisplayOrder: isNotEmpty("This field is required."),
    },
    transformValues: (values: any) => ({
      ...values,
      DisplayOrder: Number(values.DisplayOrder),
    }),
  });
  const [screen, setScreen] = useState<any>({ interval: 10 });
  const [model, setModel] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const initModel = useCallback(() => {
    return new Promise((resolve, rejet) => {
      if (id) {
        _screenService
          .getById(projectId, id)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            rejet(err);
          });
      } else {
        resolve({
          IsActive: true,
          DisplayOrder: 0,
        });
      }
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    Promise.all([initModel()]).then(([_model]: any) => {
      setModel(_model);
      form.setValues(_model);
      setLoading(false);
    });
  }, []);

  const onSubmit = (data: any) => {
    setLoading(true);
    _screenService
      .create(projectId, data)
      .then(() => {
        notify?.({
          message: "Screen has been saved successfully",
          type: "success",
        });
        onClose?.();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      {model && (
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack>
            <Checkbox
              label="Is Active"
              {...form.getInputProps("IsActive", { type: "checkbox" })}
            />

            <TextInput
              label="Name:"
              {...form.getInputProps("Name")}
              placeholder="Name"
            />

            <TextInput
              label="Display order:"
              {...form.getInputProps("DisplayOrder")}
              placeholder="DisplayOrder"
            />

            <Textarea
              label="Description:"
              {...form.getInputProps("Description")}
              placeholder="Description"
              sx={{ height: 150 }}
            />
          </Stack>
          <Group>
            <Button
              variant="subtle"
              onClick={() => {
                onClose?.();
              }}
              className="btn btn-light"
            >
              Cancel
            </Button>
            <Button type="submit" variant="filled">
              Save
            </Button>
          </Group>
        </form>
      )}
    </>
  );
};
