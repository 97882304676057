import { useNavigation, useRouterContext } from "@modir/core";
import {
  Alert,
  Anchor,
  Button,
  Card,
  Checkbox,
  Container,
  Flex,
  Group,
  Image,
  isEmail,
  isNotEmpty,
  Paper,
  PasswordInput,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
  useForm,
} from "@modir/ui-mantine";
import { useAuth } from "@providers/auth-cognito";
import { IconAlertCircle } from "@tabler/icons-react";
import { useState } from "react";
import logo from "../../assets/images/logo.svg";

export const Signup = () => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { replace } = useNavigation();

  const { signUp } = useAuth();
  const { Link } = useRouterContext();
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      given_name: "",
      family_name: "",
      mobile: "",
      termsAccepted: false,
      privacyAccepted: false,
    },
    validate: {
      email: isEmail("Valid email address is required"),
      password: isNotEmpty("Password is required"),
      given_name: isNotEmpty("First name is required"),
      family_name: isNotEmpty(":ast name is required"),
      mobile: isNotEmpty("A valid mobile number is required"),
      termsAccepted: isNotEmpty("Terms of use must be accepted"),
      privacyAccepted: isNotEmpty("Privacy policy must be accepted"),
    },
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    signUp(data)
      .then((result) => {
        debugger;
        if (!result?.userConfirmed) {
          replace("/verify", result?.codeDeliveryDetails);
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => setLoading(false));
  };
  const handleError = (errors: typeof form.errors) => {
    //console.log(errors);
  };

  return (
    <>
      <SimpleGrid
        cols={2}
        sx={{ minHeight: "100vh", width: "100%", height: "100%" }}
      >
        <Paper>
          <Flex mih="100%" justify="center" align="center">
            <Container size={600}>
              <Stack>
                <Group>
                  <Image src={logo} width={50} />
                  <div>
                    <Title order={3}>SignagePlus™</Title>
                    <Title order={5} color="gray.6">
                      by RimaSoft
                    </Title>
                  </div>
                </Group>
                <Title order={2} mt={150} fw={500} color="gray.8">
                  Start building your cloud based Signage,
                  <br /> quick and easy!
                </Title>
              </Stack>
            </Container>
          </Flex>
        </Paper>
        <Paper bg="gray.0">
          <Flex mih="100%" justify="center" align="center">
            <Card withBorder p="lg" w={500}>
              <Group position="apart" mb={20}>
                <Title order={2}>Sign up</Title>
                <Text fz="md">
                  Have an account?{" "}
                  <Anchor component={Link} to="/login">
                    Sign in
                  </Anchor>
                </Text>
              </Group>

              {error && (
                <Alert color="red" mb="sm" icon={<IconAlertCircle size={16} />}>
                  {error}
                </Alert>
              )}

              <form onSubmit={form.onSubmit(onSubmit, handleError)}>
                <Stack spacing="md">
                  <TextInput
                    label="Email"
                    size="md"
                    {...form.getInputProps("email")}
                  />

                  <PasswordInput
                    label="Password"
                    size="md"
                    {...form.getInputProps("password")}
                  />

                  <TextInput
                    label="First name"
                    size="md"
                    {...form.getInputProps("given_name")}
                  />
                  <TextInput
                    label="Last name"
                    size="md"
                    {...form.getInputProps("family_name")}
                  />

                  <TextInput
                    label="Mobile number"
                    size="md"
                    {...form.getInputProps("mobile")}
                  />

                  <Checkbox
                    label="I agree to the Terms of Use."
                    fw={600}
                    fz="md"
                    {...form.getInputProps("termsAccepted", {
                      type: "checkbox",
                    })}
                    error={form.errors.termsAccepted}
                  />

                  <Checkbox
                    label="I acknowledge the Privacy Policy."
                    fw={600}
                    fz="md"
                    {...form.getInputProps("privacyAccepted", {
                      type: "checkbox",
                    })}
                    error={form.errors.privacyAccepted}
                  />

                  <Text>
                    Please review the Terms of Use and Privacy Policy.
                  </Text>

                  <Group>
                    <Button type="submit" mt="xl" loading={loading}>
                      Create account
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Card>
          </Flex>
        </Paper>
      </SimpleGrid>
    </>
  );
};
