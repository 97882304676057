import { useState, useEffect } from "react";
import { useDataProvider, useNotification } from "@modir/core";
import Resizer from "react-image-file-resizer";
import {
  ActionIcon,
  Button,
  Card,
  Dropzone,
  FileInput,
  Group,
  Image,
  IMAGE_MIME_TYPE,
  isNotEmpty,
  Stack,
  Tabs,
  Text,
  TextInput,
  useForm,
  useMantineTheme,
} from "@modir/ui-mantine";
import { assetsService } from "@services";
import {
  IconCloudDownload,
  IconPhoto,
  IconPhotoUp,
  IconTrash,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { useAccount } from "@providers/AccountContext";

interface IImageModel {
  ParentId?: string;
  Type: string;
  Name: string;
  Extension?: string;
  MimeType: string;
  Size: number;
  Thumbnail?: any;
}

export const ImageEditor = (props: any) => {
  const [files, setFiles] = useState<File[]>([]);
  const [mediaFiles, setMediaFiles] = useState<IImageModel[]>([]);
  const [thumnail, setThumbnail] = useState<any>();
  const [thumnailFile, setThumbnailFile] = useState<any>();
  const [model, setModel] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useMantineTheme();
  const { onClose } = props;
  const dataProvider = useDataProvider();

  const { currentOrganization } = useAccount();
  const _assetService = assetsService(
    dataProvider(),
    currentOrganization?.OrganizationId!
  );
  const { open: notify } = useNotification();
  const form = useForm<Partial<IImageModel>>({});

  useEffect(() => {
    setModel({});
  }, []);
  const onSubmit = () => {
    setLoading(true);
    _assetService
      .addMedia(mediaFiles)
      .then((result) => {
        _assetService
          .uploadMedia(result.url, { file: files[0] })
          .then((resp) => {
            console.log(resp);
            notify?.({
              message: "New image has been added successfully",
              type: "success",
            });
          })
          .finally(() => {
            onClose?.(true);
            setLoading(false);
          });

        fetch(thumnail)
          .then((res) => res.blob())
          .then(console.log);

        _assetService
          .uploadMedia(result.thumbnail, { file: thumnailFile })
          .then((resp) => {
            console.log(resp);
            notify?.({
              message: "New image has been added successfully",
              type: "success",
            });
          })
          .finally(() => {
            onClose?.(true);
            setLoading(false);
          });
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  };

  const b64toByteArrays = (b64Data: any, contentType: any) => {
    contentType = contentType || "image/jpeg";
    var sliceSize = 512;

    var byteCharacters = atob(
      b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
    );
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  };
  const b64toFile = (b64Data: any, fileName: any, contentType: any) => {
    const byteArrays = b64toByteArrays(b64Data, contentType);
    const file = new File(byteArrays, fileName, {
      type: contentType,
      lastModified: new Date().getDate(),
    });
    return file;
  };

  return (
    <>
      {model && (
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Tabs defaultValue="local">
            <Tabs.List>
              <Tabs.Tab value="local" icon={<IconPhotoUp size={30} />}>
                Image file
              </Tabs.Tab>
              <Tabs.Tab value="stock" icon={<IconCloudDownload size={30} />}>
                Stock image
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="local" pt="xs">
              <Stack spacing="sm">
                {files.length === 0 && (
                  <Dropzone
                    multiple={false}
                    onDrop={(files) => {
                      var mediaFiles: any[] = [];
                      files.forEach((file) => {
                        const mediaFile: IImageModel = {
                          Type: "image",
                          ParentId: "",
                          Name: file.name,
                          Extension: file.name
                            .split(".")
                            .pop()
                            ?.toLocaleLowerCase(),
                          MimeType: file.type,
                          Size: file.size,
                        };

                        try {
                          Resizer.imageFileResizer(
                            file,
                            300,
                            300,
                            "JPEG",
                            100,
                            0,
                            (uri) => {
                              setThumbnail(uri);
                              const newFile = b64toFile(
                                uri,
                                "thumbnail",
                                file.type
                              );
                              setThumbnailFile(newFile);
                            },
                            "base64",
                            200,
                            200
                          );
                        } catch (err) {
                          console.log(err);
                        }

                        mediaFiles.push(mediaFile);
                      });
                      setMediaFiles(mediaFiles);
                      setFiles(files);
                    }}
                    onReject={(files) => console.log("rejected files", files)}
                    maxSize={5 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    {...props}
                  >
                    <Group
                      position="center"
                      spacing="xl"
                      style={{ minHeight: 220, pointerEvents: "none" }}
                    >
                      <Dropzone.Accept>
                        <IconUpload
                          size={50}
                          stroke={1.5}
                          color={
                            theme.colors[theme.primaryColor][
                              theme.colorScheme === "dark" ? 4 : 6
                            ]
                          }
                        />
                      </Dropzone.Accept>
                      <Dropzone.Reject>
                        <IconX
                          size={50}
                          stroke={1.5}
                          color={
                            theme.colors.red[
                              theme.colorScheme === "dark" ? 4 : 6
                            ]
                          }
                        />
                      </Dropzone.Reject>
                      <Dropzone.Idle>
                        <IconPhoto size={50} stroke={1.5} />
                      </Dropzone.Idle>

                      <div>
                        <Text size="xl" inline>
                          Drag image here or click to select file
                        </Text>
                        {/* <Text size="sm" color="dimmed" inline mt={7}>
                          Attach as many files as you like, each file should not
                          exceed 5mb
                        </Text> */}
                      </div>
                    </Group>
                  </Dropzone>
                )}
                {mediaFiles.map((file, index) => {
                  return (
                    <Card key={index} withBorder bg="gray.0">
                      <Group position="apart">
                        <Group spacing={5}>
                          <Image src={thumnail} width={100} mih={70} />
                          <Text>{file.Name}</Text>
                        </Group>
                        <ActionIcon variant="default">
                          <IconTrash size={16} />
                        </ActionIcon>
                      </Group>
                    </Card>
                  );
                })}

                <Group mt="lg">
                  <Button type="submit" variant="filled">
                    Upload
                  </Button>
                  <Button
                    variant="subtle"
                    onClick={() => {
                      onClose?.();
                    }}
                    className="btn btn-light"
                  >
                    Cancel
                  </Button>
                </Group>
              </Stack>
            </Tabs.Panel>
            <Tabs.Panel value="stock" pt="xs">
              comming soon
            </Tabs.Panel>
          </Tabs>
        </form>
      )}
    </>
  );
};
