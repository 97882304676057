import { useAccount } from "@providers/AccountContext";
import React from "react";
import { Helmet } from "react-helmet";

import {
  PageTitleActions,
  PageTitleContentDiv,
  PageTitleDescription,
  PageTitleDiv,
  PageTitleTitleDiv,
} from "./PageTitle.styles";

export interface IPageTitleProps {
  icon?: React.ReactNode;
  title: string;
  description?: string | React.ReactNode;
  actions?: React.ReactNode;
}

export const PageTitle: React.FC<IPageTitleProps> = ({
  icon,
  title,
  description,
  actions,
}) => {
  const { currentOrganization } = useAccount();
  return (
    <>
      <Helmet>
        <title>
          {title} | {currentOrganization?.OrganizationId ?? "No organization"} |{" "}
          {"SignagePlus"}
        </title>
      </Helmet>
      <PageTitleDiv>
        {icon}
        <PageTitleContentDiv>
          <PageTitleTitleDiv>{title}</PageTitleTitleDiv>
          <PageTitleDescription>{description}</PageTitleDescription>
        </PageTitleContentDiv>
        <PageTitleActions>{actions}</PageTitleActions>
      </PageTitleDiv>
    </>
  );
};
