import { useCallback, useEffect, useMemo, useState } from "react";
import { Collection } from "@modir/icons";
import {
  useDataProvider,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@modir/core";
import { Layout, PageTitle } from "@components";
import { TableButton, TableWrapper } from "@components/styled";
import { screenService } from "@services/screen.service";
import {
  Anchor,
  Badge,
  Breadcrumbs,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  openConfirmModal,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from "@modir/ui-mantine";
import {
  IconEdit,
  IconTrash,
  IconTools,
  IconGripVertical,
  IconCheck,
  IconX,
} from "@tabler/icons-react";
import { ScreenEditor } from "./ScreenEditor";
import { useAccount } from "@providers/AccountContext";
import ReactTimeAgo from "react-time-ago";

export const Screens = () => {
  const dataProvider = useDataProvider();
  const { currentOrganization } = useAccount();
  const _screenService = useMemo(
    () => screenService(dataProvider(), currentOrganization?.OrganizationId!),
    [dataProvider, currentOrganization]
  );
  const { open: notify } = useNotification();

  const { Link } = useRouterContext();
  const { push } = useNavigation();

  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [screens, setScreens] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState<any>();
  const [projectId, setProjectId] = useState<any>();

  const loadScreens = useCallback(async () => {
    const projectId = localStorage.getItem(`sg.app.currentProject`)!;
    await _screenService
      .listAll(projectId)
      .then((result: any) => {
        setScreens(result);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const projectId = localStorage.getItem(`sg.app.currentProject`);
    if (!projectId) {
      //TODO: redirect to spaces page
    }
    setProjectId(projectId);

    loadScreens();
  }, []);

  const onScreenClick = (screen: any) => () => {
    push(`/${currentOrganization?.OrganizationId}/designer/${screen.Id}`);
  };

  const confirmDelete = (screen: any) =>
    openConfirmModal({
      title: <Title order={5}>Delete 1 screen</Title>,
      children: (
        <Stack>
          <Text size="sm">
            Deleting a project permanently removes the related screens as well.
          </Text>
          <Text size="sm">
            To confirm deletion, type <strong>{screen.Name}</strong> in the
            field.
          </Text>
          <TextInput placeholder={screen.Title} />
        </Stack>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      onConfirm: () => {
        setLoading(true);
        _screenService
          .delete(projectId, screen.Id)
          .then(() => {
            notify?.({
              message: "1 screen has been deleted successfully",
              type: "success",
            });
            loadScreens();
          })
          .finally(() => setLoading(false));
      },
    });

  const items = [
    { title: "Home", to: `/${currentOrganization?.OrganizationId}` },
    {
      title: "Projects",
      to: `/${currentOrganization?.OrganizationId}/projects`,
    },
    { title: "Screens" },
  ].map((item, index) =>
    item.to ? (
      <Anchor component={Link} to={item.to} key={index} size="sm">
        {item.title}
      </Anchor>
    ) : (
      <Text key={index} size="sm">
        {item.title}
      </Text>
    )
  );

  return (
    <Layout.ContentLayout>
      <PageTitle
        title="Screens"
        description="Manage screens"
        icon={<Collection />}
      />
      <Breadcrumbs mt="md">{items}</Breadcrumbs>

      <TableWrapper>
        <Table verticalSpacing="sm" fontSize="sm" highlightOnHover>
          <colgroup>
            <col width={40} />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col width={400} />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Created on</th>
              <th>Interval (seconds)</th>
              <th>Status</th>
              <th>Display Order</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {screens.map((screen: any, index) => {
              return (
                <tr key={index}>
                  <td>
                    <IconGripVertical size={16} />
                  </td>
                  <td>{screen.Name}</td>
                  <td>
                    <ReactTimeAgo date={screen.CreatedAt} />
                  </td>
                  <td>{screen.Interval}</td>
                  <td>
                    {screen.Published ? (
                      <Badge color="green">Published</Badge>
                    ) : (
                      <Badge color="yellow">Draft</Badge>
                    )}
                  </td>
                  <td>{screen.DisplayOrder}</td>
                  <td>{screen.IsActive ? <IconCheck /> : <IconX />}</td>
                  <td>
                    <Group spacing="xs">
                      <Button
                        variant="default"
                        size="xs"
                        onClick={onScreenClick(screen)}
                        leftIcon={<IconTools size={14} />}
                      >
                        Design
                      </Button>
                      <Button
                        variant="default"
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedScreen(screen);
                          setModal(true);
                        }}
                        leftIcon={<IconEdit size={14} />}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="default"
                        size="xs"
                        leftIcon={<IconTrash size={14} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedScreen(screen);
                          confirmDelete(screen);
                        }}
                      >
                        Delete
                      </Button>
                    </Group>
                  </td>
                </tr>
              );
            })}
            {screens.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <div>No data available</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <TableButton
        onClick={() => {
          setSelectedScreen(undefined);
          setModal(true);
        }}
      >
        + New Screen
      </TableButton>
      <LoadingOverlay visible={loading} />
      <Modal
        opened={modal}
        size="lg"
        onClose={() => setModal(false)}
        closeOnClickOutside={false}
        title={
          <Stack spacing="xs">
            <Text fw="bold" fz="sm">
              {selectedScreen?.Id ? "Edit screen" : "Add new screen"}
            </Text>
            <Divider variant="dashed" />
          </Stack>
        }
      >
        {modal && (
          <ScreenEditor
            projectId={projectId}
            id={selectedScreen?.Id}
            onClose={() => {
              setModal(false);
              loadScreens();
            }}
          />
        )}
      </Modal>
    </Layout.ContentLayout>
  );
};
