import React from "react";
import {
  UserComponent,
  useNode,
  useEditor,
  BuilderBlock,
  Container,
  StyleSettings,
  IBuilderBlockProps,
  defaultStyleProps,
  defaultCssStyles,
} from "@modir/builder";
import moment from "moment";
import { TimeSettings } from "./TimeSettings";
import styled from "@emotion/styled";
import Clock from "react-live-clock";

export interface ITimeProps extends Omit<IBuilderBlockProps, "color"> {
  fontSize: string;
  textAlign: string;
  fontWeight: string;
  //color: Record<"r" | "g" | "b" | "a", string>;
  shadow: number;
}

const TimeDiv = styled.div<any>`
  ${defaultCssStyles}
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: ${(props) => `${props.fontWeight}`};
  text-align: ${(props) => `${props.textAlign}`};
  color: ${(props) =>
    props.color ? `rgba(${Object.values(props.color)})` : null};
`;

export const Time: UserComponent<Partial<ITimeProps>> = ({
  fontSize,
  fontWeight,
  textAlign,
  //color,
  shadow,
  ...rest
}) => {
  const {
    connectors: { connect },
    setProp,
  } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <BuilderBlock {...rest}>
      <TimeDiv
        ref={connect as any}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
        //color={color}
        shadow={shadow}
      >
        <Clock format={"HH:mm:ss"} ticking={true} />
      </TimeDiv>
    </BuilderBlock>
  );
};

Time.craft = {
  related: {
    toolbar: TimeSettings,
    styles: StyleSettings,
  },
};

Time.defaultProps = { ...defaultStyleProps };
