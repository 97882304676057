import { PropsWithChildren } from "react";
import {
  UserComponent,
  BuilderBlock,
  StyleSettings,
  IBuilderBlockProps,
  defaultStyleProps,
} from "@modir/builder";

import { CloudImageSettings } from "./CloudImageSettings";

export interface ICloudImageProps extends IBuilderBlockProps {
  imageUrl: string;
  imageFit?: "cover" | "contain";
  imagePosition?:
    | "center top"
    | "center center"
    | "center bottom"
    | "left center"
    | "right center"
    | "left top"
    | "right top"
    | "left bottom"
    | "right bottom";
  altText?: string;
}

export const CloudImage: UserComponent<
  Partial<PropsWithChildren<ICloudImageProps>>
> = ({
  imageUrl,
  imageFit = "cover",
  imagePosition = "center center",
  children,
  ...rest
}) => {
  return (
    <BuilderBlock
      {...rest}
      position={imageFit === "cover" ? "absolute" : undefined}
      left={imageFit === "cover" ? "0" : undefined}
      top={imageFit === "cover" ? "0" : undefined}
      zIndex={imageFit === "cover" ? 0 : undefined}
    >
      <img
        src={
          imageUrl ??
          "https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d"
        }
        style={{
          opacity: 1,
          transition: "opacity 0.2s ease-in-out 0s",
          objectFit: imageFit,
          objectPosition: imagePosition,
          position: "absolute",
          height: "100%",
          width: "100%",
          left: "0px",
          top: "0px",
        }}
        alt=""
      />
    </BuilderBlock>
  );
};

CloudImage.craft = {
  displayName: "Image",
  props: {},

  related: {
    toolbar: CloudImageSettings,
    styles: StyleSettings,
  },
};

CloudImage.defaultProps = {
  ...defaultStyleProps,
  overflow: "hidden",
  minWidth: "20px",
  minHeight: "20px",
  width: "100%",
  height: "100%",
};
