import { useEffect, useState, useCallback, useMemo } from "react";
import { useDataProvider, useNotification } from "@modir/core";

import { IconRefresh } from "@tabler/icons-react";
import {
  ActionIcon,
  Button,
  Card,
  Checkbox,
  Divider,
  Group,
  isNotEmpty,
  LoadingOverlay,
  NumberInput,
  Paper,
  SegmentedControl,
  Stack,
  Table,
  Text,
  TextInput,
  ThemeIcon,
  useForm,
} from "@modir/ui-mantine";
import { CurrencyPicker, ICurrencyItem } from "@components/CurrencyPicker";
import { marketRateService, rateService } from "@services";
import { useAccount } from "@providers/AccountContext";

export const parseData = (response: any) => {
  return {
    id: response.Id,
    createdOn: response.CreatedAt,
    currencyCode: response.LocalCurrency,
    currencyName: response.ForeignCurrency,
    flagUrl: response.FlagUrl,
    enabled: response.IsActive,
    title: response.Title,

    settings: {
      flagUrl: response.FlagUrl,
      addPercentage: response.AddPercentage,
      addValue: response.AddValue,
      deductPercentage: response.DeductPercentage,
      deductValue: response.DeductValue,
      manualBuy: response.ManualBuy,
      manualSell: response.ManualSell,
      round: response.Round,
    },
  };
};

export const CurrencyEditor = (props: any) => {
  const { onClose, id } = props;

  const dataProvider = useDataProvider();
  const { currentOrganization } = useAccount();
  const _rateService = useMemo(
    () => rateService(dataProvider(), currentOrganization?.OrganizationId!),
    [dataProvider, currentOrganization]
  );
  const _marketRateService = marketRateService(dataProvider("market"));
  const { open: notify } = useNotification();

  const [model, setModel] = useState<any>();
  const [exchangeType, setExchangeType] = useState<
    "percent" | "value" | "custom" | string
  >("percent");
  const [currency, setCurrency] = useState<ICurrencyItem>();
  const [marketRates, setMarketRates] = useState<any[]>([]);
  const [marketRate, setMarketRate] = useState<number>(1);
  const [buyRate, setBuyRate] = useState<number>(1);
  const [sellRate, setSellRate] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMarketRate, setLoadingMarketRate] = useState<boolean>(false);

  //const deductPercentage = watch("Settings.DeductPercentage");
  // const addPercentage = watch("Settings.AddPercentage");
  // const deductValue = watch("Settings.DeductValue");
  // const addValue = watch("Settings.AddValue");
  // const round = watch("Settings.Round");

  const form = useForm({
    initialValues: {
      Settings: {
        DeductPercentage: 0,
        AddPercentage: 0,
        DeductValue: 0,
        AddValue: 0,
        Round: 4,
        MarketRate: 1,
        IsInverse: false,
      },
      ForeignCurrency: "",
      //CountryCode: "",
      Title: "",
      IsActive: true,
    },
    validate: {
      ForeignCurrency: isNotEmpty("This field is required."),
      Title: isNotEmpty("This field is required."),
      Settings: {
        Round: isNotEmpty("This field is required."),
      },
    },
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([loadMarketRates(), initModel()]).then(
      ([_rates, _model]: any) => {
        setMarketRates(_rates);

        _model.Buy = _model.Buy ?? {};
        _model.Sell = _model.Sell ?? {};
        setModel(_model);
        setCurrency(_model.ForeignCurrency);

        form.setValues(_model);

        if (_model.ForeignCurrency) {
          let mr = Number(_rates[_model.ForeignCurrency]);
          mr = mr !== 0 ? 1 / mr : mr;
          mr = _model.Settings.IsInverse ? 1 / mr : mr;
          setMarketRate(mr);
          form.setFieldValue("MarketRate", mr);
          calculateRates();
          // calculateRates(
          //   mr,
          //   _model.Settings.DeductPercentage,
          //   _model.Settings.AddPercentage,
          //   _model.Settings.DeductValue,
          //   _model.Settings.AddValue,
          //   _model.Settings.Round
          // );
        }
        setLoading(false);
      }
    );
  }, []);

  // useEffect(() => {
  //   if (currency) {
  //     calculateRates(
  //       marketRate,
  //       deductPercentage ?? 0,
  //       addPercentage ?? 0,
  //       deductValue ?? 0,
  //       addValue ?? 0,
  //       round
  //     );
  //   }
  // }, [
  //   marketRate,
  //   deductPercentage,
  //   addPercentage,
  //   addValue,
  //   deductValue,
  //   round,
  // ]);

  useEffect(() => {
    calculateRates();
  }, [form.values]);

  const initModel = useCallback(() => {
    return new Promise((resolve, rejet) => {
      if (id) {
        _rateService
          .getById(id)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            rejet(err);
          });
      } else {
        resolve({
          Settings: {
            DeductPercentage: 0,
            AddPercentage: 0,
            DeductValue: 0,
            AddValue: 0,
            Round: 4,
          },
        });
      }
    });
  }, []);

  const loadMarketRates = useCallback(() => {
    const baseCurrency: string = "AUD";
    return new Promise(async (resolve, reject) => {
      _marketRateService.getMarketRates(baseCurrency).then((reslt: any) => {
        resolve(reslt.rates);
      });
    });
  }, []);

  const calculateRates = () =>
    //_marketRate: number,
    //_deductPercentage: number,
    //_addPercentage: number,
    // _deductValue: number,
    // _addValue: number,
    //_round?: number
    {
      const _marketRate = form.values.Settings.IsInverse
        ? 1 / marketRate
        : marketRate;
      const _deductPercentage = form.values.Settings.DeductPercentage;
      const _addPercentage = form.values.Settings.AddPercentage;
      const _deductValue = form.values.Settings.DeductValue;
      const _addValue = form.values.Settings.AddValue;
      const _round = form.values.Settings.Round;

      if (form.values.ForeignCurrency) {
        //BUY Rate
        let buy: number = 0;
        if (exchangeType === "percent") {
          buy = Number(
            (_marketRate - (_deductPercentage * _marketRate) / 100).toFixed(
              _round ?? 4
            )
          );
        } else if (exchangeType === "value") {
          buy = Number((_marketRate - _deductValue).toFixed(_round ?? 4));
        } else {
          buy = _marketRate;
        }

        if (form.values.Settings.IsInverse && buy !== 0) {
          buy = Number((1 / buy).toFixed(_round ?? 4));
        }
        setBuyRate(buy);
        form.setFieldValue("Buy.Rate", buy);

        //SELL Rate
        let sell: number = 0;
        if (exchangeType === "percent") {
          sell = Number(
            (_marketRate + (_addPercentage * _marketRate) / 100).toFixed(
              _round ?? 4
            )
          );
        } else if (exchangeType === "value") {
          sell = Number((_marketRate + _addValue).toFixed(_round ?? 4));
        } else {
          sell = _marketRate;
        }

        if (form.values.Settings.IsInverse && sell !== 0) {
          sell = Number((1 / sell).toFixed(_round ?? 4));
        }

        setSellRate(sell);
        form.setFieldValue("Sell.Rate", sell);
      }
    };

  const onSubmit = (data: any) => {
    if (id) {
      _rateService
        .update(data)
        .then(() => {
          notify?.({
            message: "Exchanre rate has been saved successfully",
            type: "success",
          });
          onClose?.();
        })
        .catch((err) => {
          notify?.({
            message: "An error occured. Please try again.",
            type: "error",
          });
        });
    } else {
      _rateService
        .create(data)
        .then(() => {
          notify?.({
            message: "Exchanre rate has been added successfully",
            type: "success",
          });
          onClose?.();
        })
        .catch((err) => {
          notify?.({
            message: "An error occured. Please try again.",
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      {model && (
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
          <Stack spacing="xl">
            <Paper bg={"gray.1"} p="xs" mb="lg">
              <CurrencyPicker
                label="Currency:"
                placeholder="Select currency"
                withAsterisk
                size="lg"
                exclude={["AUD"]}
                {...form.getInputProps("ForeignCurrency")}
                onSelectItem={(item) => {
                  setCurrency(item);
                  form.setFieldValue("CountryCode", item?.CountryCode);
                  if (item) {
                    form.setFieldValue("Title", item.Name);
                    setMarketRate(
                      Number(1 / (marketRates[item.Code as any] ?? 1))
                    );
                    form.setFieldValue(
                      "MarketRate",
                      Number(1 / (marketRates[item.Code as any] ?? 1))
                    );
                  } else {
                    setMarketRate(1);
                    form.setFieldValue("MarketRate", 1);
                  }
                }}
              />
            </Paper>
            <Card p="xs" bg={"gray.0"} withBorder mb="lg">
              <Stack spacing="sm">
                <TextInput
                  {...form.getInputProps("Title")}
                  label="Currency name:"
                  withAsterisk
                  placeholder="Currency name"
                />

                {/* <TextInput
                  label="Flag Url:"
                  {...form.getInputProps("FlagUrl")}
                  placeholder="Flag Url"
                /> */}

                <NumberInput
                  step={1}
                  label="Round:"
                  withAsterisk
                  min={0}
                  {...form.getInputProps("Settings.Round")}
                  placeholder="Round"
                />

                <NumberInput
                  step={1}
                  label="Display order:"
                  withAsterisk
                  min={0}
                  {...form.getInputProps("DisplayOrder")}
                  placeholder="Display order"
                />

                <Group position="apart">
                  <Group>
                    <Checkbox
                      label="We Buy"
                      {...form.getInputProps("Buy.Enabled", {
                        type: "checkbox",
                      })}
                    />
                    <Checkbox
                      label="We Sell"
                      {...form.getInputProps("Sell.Enabled", {
                        type: "checkbox",
                      })}
                    />
                  </Group>
                  <Checkbox
                    label="Use inverse rate"
                    {...form.getInputProps("Settings.IsInverse", {
                      type: "checkbox",
                    })}
                  />
                </Group>
              </Stack>
            </Card>

            <Card withBorder>
              <Stack>
                <SegmentedControl
                  bg="blue.1"
                  data={[
                    { value: "percent", label: "Percentage" },
                    { value: "value", label: "Value" },
                    { value: "custom", label: "Custom" },
                  ]}
                  onChange={(value) => {
                    setExchangeType(value);
                  }}
                />
                <Table withBorder>
                  <thead>
                    <tr>
                      <th>
                        <Text align="center">Buy (AUD)</Text>
                      </th>
                      <th>
                        <Group position="center">
                          Market rate
                          <ActionIcon
                            component={ThemeIcon}
                            size="sm"
                            variant="gradient"
                            gradient={{ from: "teal", to: "blue", deg: 60 }}
                            loading={loadingMarketRate}
                          >
                            <IconRefresh size={14} />
                          </ActionIcon>
                        </Group>
                      </th>
                      <th>
                        <Text align="center">Sell (AUD)</Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {exchangeType !== "custom" ? (
                          <TextInput
                            value={buyRate}
                            readOnly
                            variant="filled"
                          />
                        ) : (
                          <NumberInput
                            value={buyRate}
                            precision={form.values.Settings.Round}
                            onChange={(value: any) => setBuyRate(value)}
                          />
                        )}
                      </td>
                      <td>
                        {/* {exchangeType !== "value" ? ( */}
                        <TextInput
                          value={marketRate}
                          readOnly
                          variant="filled"
                        />
                        {/* ) : (
                          <NumberInput
                            value={marketRate}
                            precision={form.values.Round}
                            onChange={(value) => setMarketRate(value!)}
                          />
                        )} */}
                      </td>
                      <td>
                        {exchangeType !== "custom" ? (
                          <TextInput
                            value={sellRate}
                            readOnly
                            variant="filled"
                          />
                        ) : (
                          <NumberInput
                            value={sellRate}
                            onChange={(value: any) => setSellRate(value)}
                            precision={form.values.Settings.Round}
                          />
                        )}
                      </td>
                    </tr>
                    {exchangeType === "percent" && (
                      <tr>
                        <td>
                          <NumberInput
                            {...form.getInputProps("Settings.DeductPercentage")}
                            label="Deduct %:"
                            placeholder="Deduct %"
                            precision={2}
                            min={0}
                          />
                        </td>
                        <td></td>
                        <td>
                          <NumberInput
                            {...form.getInputProps("Settings.AddPercentage")}
                            label="Add %:"
                            placeholder="Add %"
                            precision={2}
                            min={0}
                          />
                        </td>
                      </tr>
                    )}
                    {exchangeType === "value" && (
                      <tr>
                        <td>
                          <NumberInput
                            {...form.getInputProps("Settings.DeductValue")}
                            label="Deduct value:"
                            placeholder="Deduct value"
                            step={0.01}
                            precision={form.values.Settings.Round}
                            min={marketRate}
                          />
                        </td>
                        <td></td>
                        <td>
                          <NumberInput
                            {...form.getInputProps("Settings.AddValue")}
                            label="Add value:"
                            placeholder="Add value"
                            step={0.01}
                            precision={form.values.Settings.Round}
                            min={0}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Stack>
            </Card>
            <Divider variant="dotted" />
            <Group>
              <Button
                variant="subtle"
                onClick={() => {
                  onClose?.();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Group>
          </Stack>
        </form>
      )}
    </>
  );
};
