import styled from "@emotion/styled";

export const TableWrapper = styled.div`
  border: 1px solid #ddd;
  box-shadow: none !important;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  display: table-header-group;
`;

export const Tr = styled.tr`
  color: inherit;
  height: 48px;
  display: table-row;
  outline: none;
  vertical-align: middle;
`;

export const Th = styled.th`
  display: table-cell;
  padding: 4px 56px 4px 24px;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  border-bottom-color: #ddd;

  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
`;

export const Tbody = styled.tbody`
  display: table-row-group;

  Tr {
    cursor: pointer;
    :hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
`;

export const Td = styled.td`
  display: table-cell;
  padding: 4px 56px 4px 24px;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  border-bottom-color: #ddd;

  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8125rem;
  font-weight: 400;
`;

export const TableButton = styled.button`
  color: inherit;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: none;
  box-shadow: none;
  line-height: 1.75;
  font-weight: 500;
  border-radius: 5px;
  text-transform: capitalize;

  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 16px;

  color: rgba(26, 115, 232, 1);
  border: 1px solid rgba(26, 115, 232, 0.5);

  width: 100%;

  margin-top: 30px;
  max-width: 500px;
  align-self: center;

  :hover {
    background-color: rgba(26, 115, 232, 0.1);
    border: 1px solid rgba(26, 115, 232, 1);
  }
`;
