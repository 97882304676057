import { useNode, ToolbarSection } from "@modir/builder";
import React, { useEffect, useState } from "react";
import { ToolbarImagePicker } from "./ToolbarImagePicker";
import { SectionItem, SectionItemContent } from "./ToolbarItem.styles";

export type IToolbarItemProps = {
  prefix?: string;
  label?: string;
  full?: boolean;
  propKey?: any;
  index?: any;
  children?: React.ReactNode;
  type: string;
  options?: any[];
  valueSuffix?: string;
  metadata?: any;
  onChange?: (value: any) => any;
};

export const ToolbarItem = ({
  full = false,
  propKey,
  type,
  onChange,
  index,
  children,
  valueSuffix,
  ...props
}: IToolbarItemProps) => {
  const {
    actions: { setProp },
    propValue,
  } = useNode((node) => {
    return {
      propValue: propKey ? node.data.props[propKey] : undefined,
    };
  });

  const [value, setValue] = useState();
  useEffect(() => {
    if (propValue) {
      if (Array.isArray(propValue) && index !== undefined) {
        setValue(propValue[index]);
      } else {
        setValue(propValue);
      }
    }
  }, [propValue]);

  return (
    <SectionItem>
      <SectionItemContent>
        <span>
          <span>{props.label}</span>
        </span>
        <div className="control">
          {type === "image" ? (
            <ToolbarImagePicker
              type="text"
              value={value || ""}
              onChange={(value: any) => {
                console.log(value);
                setProp(
                  (props: any) =>
                    (props[propKey] = onChange ? onChange(value) : value)
                );
              }}
              {...props}
            />
          ) : null}
          {children}
        </div>
      </SectionItemContent>
    </SectionItem>
  );
};
