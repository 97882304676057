import {
  ActionIcon,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
} from "@modir/ui-mantine";
import { IconDots } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { ImageModal } from "../CloudImage/ImageModal";

export type IToolbarImagePickerProps = {
  prefix?: string;
  label?: string;
  type: string;
  onChange?: (value: any) => void;
  value?: any;
};

export const ToolbarImagePicker = ({
  onChange,
  value,
  prefix,
  label,
  type,
  ...props
}: IToolbarImagePickerProps) => {
  const [internalValue, setInternalValue] = useState(value);
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      let val = value;
      if (type === "color" || type === "bg")
        val = `rgba(${Object.values(value)})`;
      setInternalValue(val);
    }
  }, [value, type]);

  return (
    <>
      <div style={{ width: "100%", position: "relative" }}>
        <Group spacing={0}>
          <input
            type="text"
            style={{ margin: 0, width: "83%" }}
            value={internalValue || ""}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onChange?.((e.target as any).value);
              }
            }}
            onChange={(e) => {
              setInternalValue(e.target.value);
            }}
            {...props}
          />
          <ActionIcon
            variant="filled"
            radius={0}
            p={5}
            h={25}
            w={25}
            mih={25}
            miw={25}
            onClick={() => {
              setModalOpened(true);
            }}
          >
            <IconDots size={16} />
          </ActionIcon>
        </Group>
      </div>

      <Modal
        opened={modalOpened}
        size="xl"
        onClose={() => setModalOpened(false)}
        closeOnClickOutside={false}
        sx={{ minHeight: 300 }}
        title={
          <Stack spacing="xs">
            <Text fw="bold" fz="sm">
              Choose an image
            </Text>
            <Divider variant="dashed" />
          </Stack>
        }
      >
        {modalOpened && (
          <ImageModal
            onClose={(image) => {
              setInternalValue(image.Url);
              onChange?.(image?.Url);
              setModalOpened(false);
            }}
          />
        )}
      </Modal>
    </>
  );
};
