import { useCallback, useState, useMemo, useEffect } from "react";
import { Collection } from "@modir/icons";
import {
  useDataProvider,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@modir/core";
import { Layout, PageTitle } from "@components";
import {
  Table,
  TableButton,
  TableWrapper,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@components/styled";

import {
  Anchor,
  Breadcrumbs,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  openConfirmModal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@modir/ui-mantine";
import { ProjectEditor } from "./ProjectEditor";
import { projectService } from "@services";
import {
  IconTrash,
  IconEdit,
  IconCheck,
  IconX,
  IconEye,
  IconCast,
  IconCopy,
} from "@tabler/icons-react";
import { useAccount } from "@providers/AccountContext";
import ReactTimeAgo from "react-time-ago";

export const Projects = () => {
  const dataProvider = useDataProvider();

  const { currentOrganization } = useAccount();
  const _projectService = useMemo(
    () => projectService(dataProvider(), currentOrganization?.OrganizationId!),
    [dataProvider, currentOrganization]
  );

  const { open: notify } = useNotification();

  const { Link } = useRouterContext();
  const { push } = useNavigation();

  const [loading, setLoading] = useState(true);
  const [modalOpened, setModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState<any>();

  const loadProjects = useCallback(async () => {
    await _projectService
      .listAll()
      .then((result: any) => {
        setProjects(result);
      })
      .finally(() => setLoading(false));
  }, [_projectService]);

  useEffect(() => {
    loadProjects();
  }, []);

  const onSpaceClick = (project: any) => () => {
    localStorage.setItem("sg.app.currentProject", project.Id);
    push(`/${currentOrganization?.OrganizationId}/screens`);
  };

  const confirmDelete = (project: any) =>
    openConfirmModal({
      title: <Title order={5}>Are you absolutely sure?</Title>,
      children: (
        <Stack>
          <Text size="sm">
            This action cannot be undone. This will permanently delete the{" "}
            <strong>{project.Title}</strong> project.
          </Text>
          <Text size="sm">
            Please type in the name of the project to confirm
          </Text>
          <TextInput
            label="Project name"
            withAsterisk
            placeholder={project.Title}
          />
        </Stack>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      onConfirm: () => {
        setLoading(true);
        _projectService
          .delete(project.Id)
          .then(() => {
            notify?.({
              message: "1 project has been deleted successfully",
              type: "success",
            });
            loadProjects();
          })
          .finally(() => setLoading(false));
      },
    });

  const items = [
    { title: "Home", to: `/${currentOrganization?.OrganizationId}` },
    { title: "Projects" },
  ].map((item, index) =>
    item.to ? (
      <Anchor component={Link} to={item.to} key={index} size="sm">
        {item.title}
      </Anchor>
    ) : (
      <Text key={index} size="sm">
        {item.title}
      </Text>
    )
  );

  return (
    <Layout.ContentLayout>
      <PageTitle
        title="Projects"
        description="Create a project for each currency board"
        icon={<Collection />}
      />

      <Breadcrumbs mt="md">{items}</Breadcrumbs>
      <TableWrapper>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Created on</Th>
              <Th>Active</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {projects.map((proj: any, index) => {
              return (
                <Tr key={index}>
                  <Td>{proj.Title}</Td>
                  <Td>
                    <ReactTimeAgo date={proj.CreatedAt} />
                  </Td>
                  <Td>{proj.IsActive ? <IconCheck /> : <IconX />}</Td>
                  <Td>
                    <Group spacing="xs">
                      <Button
                        variant="light"
                        size="xs"
                        leftIcon={<IconCopy size={14} />}
                        onClick={onSpaceClick(proj)}
                      >
                        Screens
                      </Button>
                      <Button
                        variant="default"
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedProject(proj);
                          setModal(true);
                        }}
                        leftIcon={<IconEdit size={14} />}
                      >
                        Edit
                      </Button>

                      <Button
                        variant="default"
                        size="xs"
                        leftIcon={<IconTrash size={14} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedProject(proj);
                          confirmDelete(proj);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        component={Link}
                        variant="default"
                        size="xs"
                        leftIcon={<IconEye size={14} />}
                        to={`/${currentOrganization?.OrganizationId}/projects/preview/${proj.Id}`}
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                        target="_blank"
                      >
                        Preview
                      </Button>
                      <Button
                        component={Link}
                        variant="default"
                        size="xs"
                        leftIcon={<IconCast size={14} />}
                        to={`/${currentOrganization?.OrganizationId}/cast/${proj.Slug}`}
                        onClick={(e: any) => {
                          e.stopPropagation();
                        }}
                        target="_blank"
                      >
                        Cast
                      </Button>
                    </Group>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableWrapper>
      <TableButton
        onClick={() => {
          setSelectedProject(undefined);
          setModal(true);
        }}
      >
        + New Project
      </TableButton>
      <LoadingOverlay visible={loading} />
      <Modal
        opened={modalOpened}
        size="lg"
        onClose={() => setModal(false)}
        sx={{ minHeight: 300 }}
        title={
          <Stack spacing="xs">
            <Text fw="bold" fz="sm">
              {selectedProject?.Id ? "Edit project" : "Add new project"}
            </Text>
            <Divider variant="dashed" />
          </Stack>
        }
      >
        {modalOpened && (
          <ProjectEditor
            id={selectedProject?.Id}
            onClose={() => {
              setModal(false);
              loadProjects();
            }}
          />
        )}
      </Modal>
    </Layout.ContentLayout>
  );
};
