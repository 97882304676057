import styled from "@emotion/styled";

export const SectionItem = styled.div`
position: relative;
`;

export const SectionItemContent = styled.label`
display: block;
padding: 4px 0;
line-height: 24px;

> * {
  float: right;
}

> span {
  float: none;
  font-size: 11px;
  color: var(--builder-option-label-color);
  display: inline-block;
}
::before,
::after {
  content: " ";
  display: table;
}

::after {
  clear: both;
}

input[type="text"] {
  background-color: var(--builder-textbox-background);
  color: var(--builder-text-color);
  border: none;
  font-size: 13px;
  padding: 0 8px;
  min-height: 27px;
  box-shadow: none;
}

.control {
  min-width: 120px;
  width: calc(100% - 120px);

  position: relative;
  display: flex;
  align-items: center;

  input[type="text"] {
    width: 100%;
    min-height: 25px;
    border-radius: 3px;
  }
}
`;