import React, { useContext, useState } from "react";
import { IAccountContext, IOrganizationMember } from "@types";
import { accountService } from "@services";
import {
  useDataProvider,
  useLogout,
  useNavigation,
  useRouterContext,
} from "@modir/core";
import { LoadingOverlay } from "@modir/ui-mantine";
import { useEffectOnce } from "@utils/useEffectOnce";
import axios from "axios";
import { useAuth } from "@providers";

declare type statusType = "loading" | "success" | "error";
export interface IAccountContextProps {
  status: statusType;
  error?: string | null;
  currentAccount?: IAccountContext | null;
  currentOrganization?: IOrganizationMember | null;
}

const AccountContext = React.createContext<IAccountContextProps | null>(null);

const AccountProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<IAccountContextProps>({
    status: "loading",
    error: null,
    currentAccount: null,
    currentOrganization: null,
  });

  const dataProvider = useDataProvider();
  const _accountService = accountService(dataProvider());
  const { getAccessToken } = useAuth();
  const { push } = useNavigation();
  const { mutate: logout } = useLogout();
  const { useLocation } = useRouterContext();
  var { pathname } = useLocation();

  useEffectOnce(() => {
    getAccessToken()
      .then((token) => {
        if (token) {
          axios.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };
          _accountService
            .getCurrentUser()
            .then((result: any) => {
              if (!result || !result.Id) {
                debugger;
                //logout
                logout();
                push("/login");
              }

              setState({
                status: "success",
                error: null,
                currentAccount: result,
                currentOrganization: result?.Organizations[0],
              });

              if (result.Organizations.length === 0) {
                push("/organizations/new");
                return;
              }
              if (result.Organizations.length === 1) {
                if (
                  !pathname.startsWith(
                    `/${result.Organizations[0].OrganizationId}`
                  )
                ) {
                  push(`/${result.Organizations[0].OrganizationId}`);
                }
                return;
              } else {
                push("/organizations");
                return;
              }
            })
            .catch((err) => {
              setState({ status: "error", error: err, currentAccount: null });
            });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <AccountContext.Provider value={state}>
      {state.status === "loading" ? (
        <LoadingOverlay visible={true} />
      ) : state.status === "error" ? (
        <div>Error:{state.error}</div>
      ) : (
        children
      )}
    </AccountContext.Provider>
  );
};

const useAccount = () => {
  const state: IAccountContextProps = useContext(
    AccountContext
  ) as IAccountContextProps;

  const isLoading = state?.status === "loading";
  const hasError = state?.status === "error";
  const isSuccess = state?.status === "success";
  const isLoaded = state?.status === "success" && state?.currentAccount;

  return {
    ...state,
    isLoading,
    hasError,
    isSuccess,
    isLoaded,
  };
};

export { AccountProvider, useAccount };
