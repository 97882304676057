import { useCallback, useState } from "react";
import { Collection } from "@modir/icons";
import {
  useDataProvider,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@modir/core";
import { Layout, PageTitle } from "@components";
import {
  Table,
  TableButton,
  TableWrapper,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@components/styled";

import {
  Anchor,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  openConfirmModal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@modir/ui-mantine";
import { useEffectOnce } from "@utils/useEffectOnce";
import {
  IconTrash,
  IconEdit,
  IconCheck,
  IconX,
  IconEye,
  IconCast,
  IconCopy,
  IconReportMoney,
} from "@tabler/icons-react";
import { useAccount } from "@providers/AccountContext";
import ReactTimeAgo from "react-time-ago";

export const Widgets = () => {
  const dataProvider = useDataProvider();

  const { currentOrganization } = useAccount();

  const { open: notify } = useNotification();

  const { Link } = useRouterContext();
  const { push } = useNavigation();

  const [loading, setLoading] = useState(true);
  const [modalOpened, setModal] = useState(false);
  const [projects, setWidgets] = useState([]);
  const [selectedWidget, setSelectedWidget] = useState<any>();

  const items = [
    { title: "Home", to: `/${currentOrganization?.OrganizationId}` },
    { title: "Widgets" },
  ].map((item, index) =>
    item.to ? (
      <Anchor component={Link} to={item.to} key={index} size="sm">
        {item.title}
      </Anchor>
    ) : (
      <Text key={index} size="sm">
        {item.title}
      </Text>
    )
  );

  return (
    <Layout.ContentLayout>
      <PageTitle
        title="Widgets"
        description="Create a project for each currency board"
        icon={<Collection />}
      />

      <Breadcrumbs mt="md">{items}</Breadcrumbs>

      <Card withBorder>
        <Grid>
          <Grid.Col md={2}>
            <Anchor
              component={Link}
              to={`/${currentOrganization?.OrganizationId}/widgets/CurrencyRates`}
            >
              <Card withBorder>
                <IconReportMoney size={30} />
                <Text>Currency Rates</Text>
              </Card>
            </Anchor>
          </Grid.Col>
        </Grid>
      </Card>
    </Layout.ContentLayout>
  );
};
