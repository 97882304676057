import styled from "@emotion/styled";

export const SidebarDiv = styled.div`
    width: 308px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;

`

export const SidebarContent = styled.div`
    width: 308px;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: stretch;
    align-items: stretch;
    overflow: auto;
    background-color: rgb(58, 58, 58);
    contain: strict;
    padding: 12px 10px;
`

export const SidebarHeader = styled.div`
    color: white;
    opacity: 1;
    transition: opacity 0.1s ease 0s;
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
`

export const LogoArea = styled.div`
    display: flex;  
    align-items: center;
    transition: opacity 0.1s ease-in-out 0s;
    cursor: pointer;
    margin-top: 0;

`
export const LogoImage = styled.div`
    margin: 4px 18px 0px 12px;
    width: 31px;
    height: 36px;
    object-fit: contain;
    object-position: center center;
    filter: none !important;
`

export const LogoContent = styled.div`
    margin: 0;
    border: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    flex-grow: 1;

    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;

    small{
        color: rgb(156, 156, 156);
        font-size: 12px;
        font-weight: 500;
        line-height: 11px;
        margin-top: 5px;
    }
`

export const HeaderSeparator = styled.hr`
    height: 1px;
    border: none;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.2);
    max-width: 52px;
    transition: max-width 0.2s cubic-bezier(0.67, 0.1, 0.27, 0.96) 0s;
    margin: 12px 0px 4px;
`



export const SidebarFooter = styled.div`
    margin-top: auto;
`

export const SidebarItem = styled.div`
    color: white;
    font-size: 14px;
    font-weight: 500;
    border: 0;
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    margin: 5px 0px;
    padding: 6px 15px;
    transition: none 0s ease 0s;
    border-radius: 4px;
    background-color: transparent;
    opacity: 0.5;
    
    :hover,.active{
        transition: none 0s ease 0s;
        border-radius: 4px;
        background-color: rgb(26, 115, 232) !important;
        opacity: 1;

        
    }
`

export const SidebarItemIcon = styled.span`
    display: inline-flex;
    position: relative;
    vertical-align: middle;

    svg{
        color: white;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 24px;
        transition: none;
        user-select: none;
        flex-shrink: 0;
    }
`

export const SidebarItemLabel = styled.div`
    flex: 1 1 auto;
    padding: 0 16px;
    min-width: 0;
    margin-left: 2px;
    font-size: 14px;
    font-weight: 500;
`