import React from "react";
import {
  Group,
  SelectItem,
  SelectProps,
  Text,
  SelectPlus,
} from "@modir/ui-mantine";
import Flag from "react-world-flags";

import data from "./data.json";
import { IconCashBanknote } from "@tabler/icons-react";
const options = data.map((x) => {
  const item = {
    value: x.CurrencyCode,
    label: x.CurrencyName,
    Code: x.CurrencyCode,
    Name: x.CurrencyName,
    CountryCode: x.CountryCode,
  };
  return item;
});

export interface ICurrencyItem extends SelectItem {
  Code: string;
  Name: string;
  CountryCode: string;
}

export interface ICurrencyPickerProps extends Omit<SelectProps, "data"> {
  currencyCode?: string;
  onSelectItem?: (currency?: ICurrencyItem) => void;
  exclude?: string[];
  include?: string[];
}

export const CurrencyPicker = ({
  currencyCode = "",
  onSelectItem,
  placeholder,
  exclude = [],
  include = [],
  ...rest
}: ICurrencyPickerProps) => {
  const inclusion =
    include.length > 0
      ? options.filter((item1) => {
          return include.find((item2) => item1.Code === item2);
        })
      : options;

  const exclusion = inclusion.filter((item1) => {
    return !exclude.find((item2) => item1.Code === item2);
  });

  const items = [...exclusion].sort((a, b) => {
    const aName = a.Code,
      bName = b.Code;
    return aName > bName ? 1 : bName > aName ? -1 : 0;
  });
  return (
    <>
      <SelectPlus
        data={items}
        placeholder={placeholder ?? "-- Select --"}
        searchable
        clearable
        {...rest}
        onSelectItem={(item: any) => {
          onSelectItem?.(item);
        }}
        selectedItemTemplate={(item: ICurrencyItem) => {
          return (
            <Group spacing="xs">
              <Flag
                code={item?.CountryCode}
                width={30}
                height={20}
                fallback={<IconCashBanknote size={30} />}
              />
              <Text fw={500} w={50}>
                {item.Code}
              </Text>
              <Text fw={400}>{item.Name}</Text>
            </Group>
          );
        }}
        itemTemplate={(item: ICurrencyItem) => {
          return (
            <Group spacing="xs">
              <Flag
                code={item?.CountryCode?.toUpperCase()}
                width={30}
                height={20}
                fallback={<IconCashBanknote size={30} />}
              />
              <Text fw={500} w={50}>
                {item.Code}
              </Text>
              <Text fw={400}>{item.Name}</Text>
            </Group>
          );
        }}
      />
    </>
  );
};
