import React, { useEffect, useState, useCallback } from "react";
import { useDataProvider } from "@modir/core";
import {
  ToolbarSection,
  ToolbarItem,
  ToolbarRadio,
  ToolbarSelect,
  ToolbarRow,
  StyledButton as AddButton,
} from "@modir/builder";

import { Gear, Pencil, Plus } from "@modir/icons";
import { CurrencyEditor } from "./CurrencyEditor";
import { Grid, LoadingOverlay, Modal } from "@modir/ui-mantine";

const parseData = (response: any) => {
  return {
    id: response.id,
    createdOn: response.created,
    currency: response.data.currency,
    flagUrl: response.data.flagUrl,
    enabled: response.data.enabled,
    addPercentage: response.data.addPercentage,
    addValue: response.data.addValue,
    deductPercentage: response.data.deductPercentage,
    deductValue: response.data.deductValue,
    manualBuy: response.data.manualBuy,
    manualSell: response.data.manualSell,
  };
};

export const CurrencyTableSettings = () => {
  const dataProvider = useDataProvider();
  const { getList } = dataProvider();

  const [currencies, setCurrencies] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<any>();

  const loadCurrencies = useCallback(async () => {
    const { data }: any = await getList<any>({
      resource: "rates",
      headers: {
        "x-api-key": process.env.CB_API_KEY,
      },
    });

    const items = data;
    setCurrencies(items);
  }, [getList]);
  const currencyToggleShow = (id: string, isActive: boolean) => {
    if (isActive) {
      console.log("Currency Id: ", id, "deActived");
    } else {
      console.log("Currency Id: ", id, "actived");
    }
  };
  useEffect(() => {
    loadCurrencies();
  }, [loadCurrencies]);
  console.log(currencies);

  return (
    <React.Fragment>
      <ToolbarSection title="Options" defaultOpen={true}>
        <ToolbarItem
          full={true}
          propKey="headerGroupTitle"
          label="Header title"
          type="text"
        />
      </ToolbarSection>
      <ToolbarSection title="Typography" defaultOpen={true}>
        <ToolbarItem
          full={true}
          propKey="fontSize"
          label="Font size"
          type="slider"
          valueSuffix="px"
        />
        <ToolbarItem
          label="Align"
          propKey="textAlign"
          type="select"
          options={[
            { label: "Left", value: "left" },
            { label: "Center", value: "center" },
            { label: "Right", value: "right" },
          ]}
        />
        <ToolbarItem
          label="Weight"
          propKey="fontWeight"
          type="select"
          options={[
            { label: "Regular", value: "400" },
            { label: "Medium", value: "500" },
            { label: "Bold", value: "700" },
          ]}
        />
      </ToolbarSection>
      <ToolbarSection title="Appearance">
        <ToolbarItem
          full={true}
          propKey="headerColor"
          type="color"
          label="Header color"
        />
        <ToolbarItem
          full={true}
          propKey="headerBgColor"
          type="color"
          label="Header background color"
        />
        <br />
        <ToolbarItem
          full={true}
          propKey="rowColor"
          type="color"
          label="Row color"
        />
        <ToolbarItem
          full={true}
          propKey="rowBgColor"
          type="color"
          label="Row background color"
        />
        <br />
        <ToolbarItem
          full={true}
          propKey="altRowColor"
          type="color"
          label="Alternate row color"
        />
        <ToolbarItem
          full={true}
          propKey="altRowBgColor"
          type="color"
          label="Alternate row background color"
        />
        <br />
        <ToolbarItem
          full={true}
          propKey="borderColor"
          type="color"
          label="Border color"
        />
      </ToolbarSection>
      <ToolbarSection
        title="Currencies"
        defaultOpen={true}
        // actions={
        //   <>
        //     <AddButton
        //       className="dark"
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         setModal(true);
        //       }}
        //     >
        //       <Plus /> Add New Currency
        //     </AddButton>
        //   </>
        // }
      >
        <ToolbarRow>
          {currencies.map((cur: any, index) => {
            return (
              <Grid key={index}>
                <Grid.Col>{cur.ForeignCurrency}</Grid.Col>
                <Grid.Col>
                  <Pencil />
                </Grid.Col>
                <Grid.Col>
                  <input
                    type="checkbox"
                    value={cur.IsActive}
                    onChange={() => currencyToggleShow(cur.Id, cur.IsActive)}
                  />
                </Grid.Col>
              </Grid>
            );
          })}
          {currencies.length == 0 && (
            <>
              <span>No currencies available</span>
            </>
          )}
        </ToolbarRow>
      </ToolbarSection>
      <LoadingOverlay visible={loading} />
      <Modal opened={modal} onClose={() => setModal(false)}>
        <CurrencyEditor
          id={selectedCurrency?.id}
          onClose={() => {
            setModal(false);
            loadCurrencies();
          }}
          setLoading={setLoading}
        />
      </Modal>
    </React.Fragment>
  );
};
