import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CalendarDate, Watch, Table, Image } from "@modir/icons";
import { useDataProvider, useRouterContext } from "@modir/core";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper";
import { useSwiper } from "swiper/react";
import { ModirPreview } from "@modir/builder";
import {
  CloudImage,
  CurrencyTable,
  CurrentDate,
  Time,
} from "@components/custom";
import { screenService } from "@services";
import { Global } from "@modir/ui-mantine";
import { useAccount } from "@providers/AccountContext";

export const ProjectCastPage = () => {
  const dataProvider = useDataProvider();
  const { currentOrganization } = useAccount();
  const _screenService = screenService(
    dataProvider(),
    currentOrganization?.OrganizationId!
  );
  const { useParams } = useRouterContext();
  const { orgId, slug } = useParams<any>();

  const [screens, setScreens] = useState([]);

  useEffect(() => {
    const loadScreens = async (orgId: string, id: string) => {
      const items: any = await _screenService.listCasting(orgId, id);

      setScreens(items);
    };

    if (orgId && slug) {
      loadScreens(orgId, slug);
    }
  }, []);

  return (
    <>
      <Global
        styles={(theme) => ({
          body: {
            ...theme.fn.fontStyles(),
            height: "100%",

            div: {
              height: "100%",
            },

            ".main-canvas": {
              height: "100vh",
            },
          },
        })}
      />

      {screens.length > 0 && (
        <Swiper
          spaceBetween={30}
          loop={true}
          //centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
        >
          {screens
            .sort((a: any, b: any) =>
              a.displayOrder > b.displayOrder ? 1 : -1
            )
            .map((screen: any, index) => (
              <SwiperSlide key={index}>
                <>
                  <ModirPreview
                    jsonContent={screen.DraftContent}
                    customResolver={{
                      CurrentDate,
                      Time,
                      CurrencyTable,
                      CloudImage,
                    }}
                    customComponents={[
                      {
                        name: "Date",
                        icon: <CalendarDate />,
                        element: <CurrentDate fontSize="16" textAlign="left" />,
                      },
                      {
                        name: "Time",
                        icon: <Watch />,
                        element: <Time fontSize="16" textAlign="left" />,
                      },
                      {
                        name: "Rates",
                        icon: <Table />,
                        element: <CurrencyTable fontSize="16" />,
                      },
                      {
                        name: "Image+",
                        icon: <Image />,
                        element: <CloudImage />,
                      },
                    ]}
                  />
                </>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </>
  );
};
