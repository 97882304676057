import { useCallback, useState, useEffect } from "react";
import { useDataProvider, useNotification } from "@modir/core";
import {
  Button,
  Group,
  isNotEmpty,
  LoadingOverlay,
  Stack,
  Textarea,
  TextInput,
  useForm,
} from "@modir/ui-mantine";
import { accountService, assetsService } from "@services";
import { PageTitle } from "@components/layouts";
import { IconBuilding, IconBuildingArch } from "@tabler/icons-react";
import { useAccount } from "@providers/AccountContext";

export const FolderEditor = (props: any) => {
  const { onClose, id } = props;
  const dataProvider = useDataProvider();

  const { currentOrganization } = useAccount();
  const _assetService = assetsService(
    dataProvider(),
    currentOrganization?.OrganizationId!
  );
  const { open: notify } = useNotification();
  const form = useForm({
    initialValues: {
      Name: "",
    },
    validate: {
      Name: isNotEmpty("This field is required."),
    },
    transformValues: (values) => ({
      ...values,
      Type: "folder",
    }),
  });

  const [model, setModel] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setModel({});
  }, []);
  const onSubmit = (data: any) => {
    setLoading(true);
    _assetService
      .createFolder(data)
      .then(() => {
        notify?.({
          message: "New folder has been created successfully",
          type: "success",
        });
        onClose?.(true);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {model && (
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack>
            <TextInput
              label="Name:"
              withAsterisk
              placeholder="New folder"
              {...form.getInputProps("Name")}
            />

            <Group>
              <Button
                variant="subtle"
                onClick={() => {
                  onClose?.();
                }}
                className="btn btn-light"
              >
                Cancel
              </Button>
              <Button type="submit" variant="filled">
                Save
              </Button>
            </Group>
          </Stack>
        </form>
      )}
    </>
  );
};
