import React, { useState } from "react";
import { IModirProps, Modir } from "@modir/core";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { AccountProvider, notificationProvider } from "./providers";
import {
  MantineProvider,
  Global,
  createEmotionCache,
  ColorSchemeProvider,
  ColorScheme,
  Notifications,
} from "@modir/ui-mantine";
import { ModalsProvider } from "@mantine/modals";

// import {
//   LoginPage as AdminLoginPage,
//   Layout as PortalLayout,
// } from "./components";

import { Dashboard } from "./pages";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useCallback } from "react";
const modirCache = createEmotionCache({ key: "modir" });

export interface IModirWrapperProps extends Omit<IModirProps, "Layout"> {
  Layout?: any;
}

const rollbarConfig = {
  accessToken: "ef27ebd1a9e640fc87188a125fa5a50d",
  environment: "testenv",
};
const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY!;

export const ModirWrapper: React.FC<
  React.PropsWithChildren<IModirWrapperProps>
> = ({
  authProvider,
  dataProvider,
  routerProvider,
  children,
  warnWhenUnsavedChanges,
  Layout,
  Sider,
  Header,
  Footer,
  Title,
  DashboardPage,
  reactQueryClientConfig,
  reactQueryDevtoolConfig,
  LoginPage,
}) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const verifyRecaptchaCallback = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const queryClient = {
    defaultOptions: {
      mutations: {
        // retry: (retryCount: any, error: any) => {
        //   debugger;
        //   if (error.statusCode === 200) {
        //     return false;
        //   }
        //   return true;
        // },
      },
      queries: {
        // retry: (retryCount: any, error: any) => {
        //   debugger;
        //   if (error.statusCode === 200) {
        //     return false;
        //   }
        //   return true;
        // },
      },
    },
  };

  return (
    // <Provider config={rollbarConfig}>
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <ErrorBoundary>
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            emotionCache={modirCache}
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colorScheme,
            }}
          >
            <ModalsProvider>
              <Global
                styles={{ body: { WebkitFontSmoothing: "auto", fontSize: 12 } }}
              />
              <Notifications position="top-right" />
              <Modir
                notificationProvider={notificationProvider}
                authProvider={authProvider}
                dataProvider={dataProvider}
                routerProvider={routerProvider}
                warnWhenUnsavedChanges={warnWhenUnsavedChanges}
                LoginPage={LoginPage}
                DashboardPage={DashboardPage ?? Dashboard}
                Layout={Layout}
                Sider={Sider}
                Header={Header}
                Footer={Footer}
                Title={Title}
                reactQueryClientConfig={queryClient}
                reactQueryDevtoolConfig={reactQueryDevtoolConfig}
              >
                <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
                {/* <AccountProvider>{children}</AccountProvider> */}
              </Modir>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </ErrorBoundary>
    </GoogleReCaptchaProvider>
    // </Provider>
  );
};
