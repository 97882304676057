import { ILoginPageProps, useRouterContext, useTranslate } from "@modir/core";
import {
  Alert,
  Anchor,
  BackgroundImage,
  BoxProps,
  Button,
  Card,
  CardProps,
  Flex,
  Group,
  Image,
  isEmail,
  isNotEmpty,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
  useForm,
} from "@modir/ui-mantine";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import logo from "../../assets/images/logo.svg";
import bg from "../../assets/images/bg10.jpeg";
import { useState } from "react";
import { useCallback } from "react";
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react";
import { useAuth } from "@providers/auth-cognito";

type ILoginProps = ILoginPageProps<BoxProps, CardProps, any>;

export const Verify = ({ formProps }: any) => {
  const { onSubmit: onSubmitProp, ...useFormProps } = formProps || {};
  const translate = useTranslate();
  const { Link } = useRouterContext();
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [error, setError] = useState<string>();
  const [sendingCode, setSendingCode] = useState<boolean>(false);
  const [requestingCode, setRequestingCode] = useState<boolean>(false);
  const [successResend, setSuccessResend] = useState<boolean>(false);

  const verifyRecaptchaCallback = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const { confirmAccount, isAuthenticating, resendSignupCode } = useAuth();

  const form = useForm({
    initialValues: {
      email: "",
      code: "",
    },
    validate: {
      email: isEmail(
        translate("pages.login.errors.validEmail", "Enter a valid email")
      ),
      code: isNotEmpty(
        translate(
          "pages.login.errors.verificationCode",
          "Enter verification code"
        )
      ),
    },
  });

  const onSubmit = async (values: any) => {
    setSendingCode(true);
    confirmAccount(values)
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        setSuccessResend(false);
        setError(error.message);
      })
      .finally(() => setSendingCode(false));
  };

  const handleResendCode = () => {
    if (!form.values.email) {
      form.validateField("email");
    } else {
      setRequestingCode(true);
      form.setFieldValue("code", "");
      resendSignupCode({ email: form.values.email })
        .then((result) => {
          setError(undefined);
          setSuccessResend(true);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => setRequestingCode(false));
    }
  };
  return (
    <BackgroundImage src={bg}>
      <Flex mih="100vh" align="center" justify="center">
        <Card withBorder bg="gray.0" w={500}>
          <Card.Section p="lg" withBorder>
            <Group position="center">
              <Image src={logo} width={50} />
              <div>
                <Title order={6}>Money Studio™</Title>
                <Title order={5} color="gray.7">
                  Currency Board
                </Title>
              </div>
            </Group>
          </Card.Section>
          <Card.Section>
            <GoogleReCaptchaProvider reCaptchaKey="6LewHNoZAAAAABRKdacjpX8o441Fcq7rk7UhlW1V">
              <form onSubmit={form.onSubmit(onSubmit)}>
                <Paper radius={0} p={30}>
                  <Stack>
                    <Text size="lg" fw={600} mb="lg">
                      Verify your email
                    </Text>
                    {error && (
                      <Alert
                        color="red"
                        mb="sm"
                        icon={<IconAlertCircle size={16} />}
                      >
                        {error}
                      </Alert>
                    )}
                    {successResend && (
                      <Alert color="green" icon={<IconCircleCheck size={16} />}>
                        A verification code has been sent to your email.
                      </Alert>
                    )}
                    <TextInput
                      label="Email address"
                      size="md"
                      autoComplete="value"
                      {...form.getInputProps("email")}
                    />
                    <TextInput
                      label="Enter verification code"
                      size="md"
                      autoComplete="value"
                      {...form.getInputProps("code")}
                    />

                    <Group position="right">
                      <Button
                        variant="light"
                        size="xs"
                        onClick={handleResendCode}
                        loading={requestingCode}
                      >
                        Request new Code
                      </Button>
                    </Group>

                    <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />

                    <Button
                      fullWidth
                      mt="xl"
                      size="md"
                      type="submit"
                      loading={sendingCode}
                    >
                      Verify
                    </Button>

                    <Text align="center" mt="md">
                      Already have an account?{" "}
                      <Anchor component={Link} weight={700} to="/login">
                        Login
                      </Anchor>
                    </Text>
                  </Stack>
                </Paper>
              </form>
            </GoogleReCaptchaProvider>
          </Card.Section>
        </Card>
      </Flex>
    </BackgroundImage>
  );
};
