import { useCallback, useMemo, useEffect, useState } from "react";
import { CurrencyExchange } from "@modir/icons";

import {
  useDataProvider,
  useNotification,
  useRouterContext,
} from "@modir/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Layout, PageTitle } from "@components";
import { TableButton, TableWrapper } from "@components/styled";
import {
  Anchor,
  Badge,
  Breadcrumbs,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  openConfirmModal,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  useListState,
} from "@modir/ui-mantine";
import {
  IconEdit,
  IconTrash,
  IconCashBanknote,
  IconGripVertical,
} from "@tabler/icons-react";
import { CurrencyEditor } from "@components/custom/CurrencyTable/CurrencyEditor";
import { rateService } from "@services/rate.service";
import Flag from "react-world-flags";
import { useAccount } from "@providers/AccountContext";
import ReactTimeAgo from "react-time-ago";

export const Currencies = () => {
  const [loading, setLoading] = useState(true);

  const dataProvider = useDataProvider();
  const { currentOrganization } = useAccount();
  const _rateService = useMemo(
    () => rateService(dataProvider(), currentOrganization?.OrganizationId!),
    [dataProvider, currentOrganization]
  );
  const { open: notify } = useNotification();
  const { Link } = useRouterContext();

  const [modal, setModal] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const [state, handlers] = useListState(currencies);

  const loadCurrencies = useCallback(async () => {
    await _rateService
      .listAll()
      .then((result: any) => {
        setCurrencies(result);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadCurrencies();
  }, [loadCurrencies]);

  const confirmDelete = (currency: any) =>
    openConfirmModal({
      title: <Title order={5}>Delete 1 currency</Title>,
      children: (
        <Stack>
          <Text size="sm">
            Deleting a currency permanently will be removed from related screens
            as well.
          </Text>
          <Text size="sm">
            To confirm deletion, type <strong>{currency.Code}</strong> in the
            field.
          </Text>
          <TextInput placeholder={currency.Code} />
        </Stack>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      onConfirm: () => {
        setLoading(true);
        _rateService
          .delete(currency.Id)
          .then(() => {
            notify?.({
              message: "1 currency has been deleted successfully",
              type: "success",
            });
            loadCurrencies();
          })
          .finally(() => setLoading(false));
      },
    });

  const items = [{ title: "Home", to: "/" }, { title: "Currencies" }].map(
    (item, index) =>
      item.to ? (
        <Anchor component={Link} to={item.to} key={index} size="sm">
          {item.title}
        </Anchor>
      ) : (
        <Text key={index} size="sm">
          {item.title}
        </Text>
      )
  );

  return (
    <Layout.ContentLayout>
      <PageTitle
        title="Currency Settings"
        description="Manage currency exchange rate settings"
        icon={<CurrencyExchange />}
      />
      <Breadcrumbs mt="md">{items}</Breadcrumbs>
      <DragDropContext
        onDragEnd={({ destination, source }) =>
          handlers.reorder({ from: source.index, to: destination?.index || 0 })
        }
      >
        <TableWrapper>
          <Table highlightOnHover fontSize="sm" verticalSpacing="md">
            <colgroup>
              <col width={80} />
              <col width={80} />
              <col />
              <col width={100} />
              <col width={100} />
              <col width={100} />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Text align="center">Flag</Text>
                </th>
                <th>
                  <Text align="center">Code</Text>
                </th>
                <th>Name</th>
                <th>
                  Buy{" "}
                  <Badge
                    size="xs"
                    radius="xs"
                    variant="gradient"
                    gradient={{ from: "teal", to: "blue", deg: 60 }}
                  >
                    AUD
                  </Badge>
                </th>
                <th>Market</th>
                <th>
                  Sell{" "}
                  <Badge
                    size="xs"
                    radius="xs"
                    variant="gradient"
                    gradient={{ from: "teal", to: "blue", deg: 60 }}
                  >
                    AUD
                  </Badge>
                </th>

                <th>Last update</th>
                <th>Actions</th>
              </tr>
            </thead>
            <Droppable droppableId="dnd-list" direction="vertical">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {currencies.map((cur: any, index) => (
                    <Draggable
                      key={cur.ForeignCurrency}
                      index={index}
                      draggableId={cur.ForeignCurrency}
                    >
                      {(provided) => (
                        <tr
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <td>
                            <div {...provided.dragHandleProps}>
                              <IconGripVertical size="1.05rem" stroke={1.5} />
                            </div>
                          </td>
                          <td>
                            {/* <Image
                      src={cur.FlagUrl}
                      height={30}
                      withPlaceholder
                      fit="contain"
                    /> */}
                            <Flag
                              code={cur.CountryCode?.toUpperCase()}
                              width={50}
                              fallback={<IconCashBanknote size={50} />}
                            />
                          </td>
                          <td>
                            <Text fz="sm" fw={600} align="center">
                              {cur.ForeignCurrency}
                            </Text>
                          </td>
                          <td>
                            <Text fz="sm">{cur.Title}</Text>
                          </td>
                          <td>
                            <Text fz="sm" fw={500}>
                              {cur.Buy?.Enabled
                                ? cur?.Buy?.Rate?.toFixed(
                                    cur.Settings?.Round
                                  ) ?? "-"
                                : "-"}
                            </Text>
                          </td>
                          <td>
                            <Text fz="sm" color="dimmed">
                              {cur.MarketRate?.toFixed(cur.Settings?.Round) ??
                                "-"}
                            </Text>
                          </td>
                          <td>
                            <Text fz="sm" fw={500}>
                              {cur.Sell?.Enabled
                                ? cur.Sell?.Rate?.toFixed(
                                    cur.Settings?.Round
                                  ) ?? "-"
                                : "-"}
                            </Text>
                          </td>

                          <td>
                            {cur.Timestamp ? (
                              <ReactTimeAgo date={cur.Timestamp} />
                            ) : (
                              "-"
                            )}
                          </td>

                          <td>
                            <Group spacing="xs">
                              <Button
                                variant="default"
                                size="xs"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedCurrency(cur);
                                  setModal(true);
                                }}
                                leftIcon={<IconEdit size={14} />}
                              >
                                Edit
                              </Button>

                              <Button
                                variant="default"
                                size="xs"
                                leftIcon={<IconTrash size={14} />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedCurrency(cur);
                                  confirmDelete(cur);
                                }}
                              >
                                Delete
                              </Button>
                            </Group>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}

                  {currencies.length === 0 && (
                    <tr>
                      <td colSpan={8}>
                        <Text align="center">No data available</Text>
                      </td>
                    </tr>
                  )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </Table>
        </TableWrapper>
      </DragDropContext>
      <TableButton
        onClick={() => {
          setSelectedCurrency(undefined);
          setModal(true);
        }}
      >
        + New Currency
      </TableButton>
      <LoadingOverlay visible={loading} />
      <Modal
        opened={modal}
        size="lg"
        onClose={() => setModal(false)}
        closeOnClickOutside={false}
        title={
          <Stack spacing="xs">
            <Text fw="bold" fz="sm">
              {selectedCurrency?.Id
                ? "Edit exchange rate"
                : "Add new exchange rate"}
            </Text>
            <Divider variant="dashed" />
          </Stack>
        }
      >
        {modal && (
          <CurrencyEditor
            id={selectedCurrency?.Id}
            onClose={() => {
              setModal(false);
              loadCurrencies();
            }}
          />
        )}
      </Modal>
    </Layout.ContentLayout>
  );
};
