import { useEffect, useCallback, useState } from "react";
import { Layout, PageTitle } from "@components/layouts";
import {
  ActionIcon,
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  CopyButton,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Menu,
  Modal,
  Paper,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
} from "@modir/ui-mantine";
import {
  IconChevronDown,
  IconCirclePlus,
  IconCopy,
  IconCornerUpRight,
  IconDotsVertical,
  IconDownload,
  IconFiles,
  IconFolder,
  IconFolderPlus,
  IconList,
  IconPhoto,
  IconPhotoPlus,
  IconReload,
  IconSquareToggle,
  IconTags,
  IconTrash,
  IconVideoPlus,
} from "@tabler/icons-react";
import { useDataProvider, useRouterContext } from "@modir/core";
import { useAccount } from "@providers/AccountContext";
import { assetsService } from "@services/assets.service";
import { FolderEditor } from "./FolderEditor";
import { ImageEditor } from "./ImageEditor";
import ReactTimeAgo from "react-time-ago";
import { ModirAssets } from "./ModirAssets";

export const Assets = () => {
  const { Link } = useRouterContext();
  const { currentOrganization } = useAccount();
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [folderModalOpened, setFolderModalOpened] = useState(false);
  const [imageModalOpened, setImageModalOpened] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<any>();

  const dataProvider = useDataProvider();
  const _assetsService = assetsService(
    dataProvider(),
    currentOrganization?.OrganizationId!
  );

  const bcItems = [
    { title: "Home", to: `/${currentOrganization?.OrganizationId}` },
    { title: "Assets" },
  ].map((item, index) =>
    item.to ? (
      <Anchor component={Link} to={item.to} key={index} size="sm">
        {item.title}
      </Anchor>
    ) : (
      <Text key={index} size="sm">
        {item.title}
      </Text>
    )
  );

  const loadAssets = useCallback(async () => {
    setLoading(true);
    await _assetsService
      .listAll("")
      .then((result: any) => {
        setItems(result);
      })
      .finally(() => setLoading(false));
  }, [_assetsService]);

  useEffect(() => {
    loadAssets();
  }, []);

  return (
    <Layout.ContentLayout>
      <PageTitle
        title="Assets"
        description="Manage images and video files"
        icon={<IconFiles size={60} stroke={1} />}
      />

      <Breadcrumbs mt="md">{bcItems}</Breadcrumbs>

      <ModirAssets />
      {/* <LoadingOverlay visible={loading} />

      <Card withBorder p="xs" mt="lg" pos="inherit">
        <Group position="apart">
          <Group>
            <Menu shadow="md" width={200} position="bottom-start">
              <Menu.Target>
                <Button
                  size="xs"
                  variant="default"
                  leftIcon={<IconCirclePlus size={18} stroke={1} />}
                  rightIcon={<IconChevronDown size={14} stroke={1} />}
                >
                  New
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconFolderPlus size={20} stroke={1} />}
                  onClick={() => {
                    setSelectedAsset(null);
                    setFolderModalOpened(true);
                  }}
                >
                  Folder
                </Menu.Item>
                <Menu.Item
                  icon={<IconPhotoPlus size={18} stroke={1} />}
                  onClick={() => {
                    setImageModalOpened(true);
                  }}
                >
                  Image
                </Menu.Item>
                <Menu.Item icon={<IconVideoPlus size={22} stroke={1} />}>
                  Video
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
          <Group spacing="xs">
            <ActionIcon variant="default">
              <IconList size={16} />
            </ActionIcon>
            <ActionIcon variant="default">
              <IconReload size={16} />
            </ActionIcon>
          </Group>
        </Group>
      </Card>

      <Card withBorder mt="sm" pos="inherit">
        <Grid>
          {items.map((item, index) => {
            return (
              <Grid.Col md={2} key={index}>
                {item.Type === "folder" && (
                  <Card withBorder p="sm" mih={150}>
                    <Stack align="center" spacing={0}>
                      <IconFolder size={60} stroke={1} />
                      <Text fz="md">{item.Name}</Text>
                    </Stack>
                  </Card>
                )}
                {item.Type === "image" && (
                  <Paper mih={150}>
                    <UnstyledButton w="100%">
                      <Card withBorder p={5} pos="inherit">
                        <Stack spacing={7}>
                          <Group position="apart">
                            <Checkbox size="xs" />
                            <Group spacing={0}>
                              <ActionIcon>
                                <IconDownload size={16} />
                              </ActionIcon>
                              <Menu
                                shadow="md"
                                width={200}
                                position="bottom-start"
                              >
                                <Menu.Target>
                                  <ActionIcon>
                                    <IconDotsVertical size={16} />
                                  </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                  <Menu.Item
                                    icon={<IconTags size={16} stroke={1} />}
                                    onClick={() => {}}
                                  >
                                    Edit Tags
                                  </Menu.Item>
                                  <Menu.Item
                                    icon={<IconCopy size={16} stroke={1} />}
                                    onClick={() => {
                                      setImageModalOpened(true);
                                    }}
                                  >
                                    Duplicate asset
                                  </Menu.Item>
                                  <Menu.Divider />
                                  <Menu.Item
                                    icon={
                                      <IconCornerUpRight size={16} stroke={1} />
                                    }
                                  >
                                    Move to
                                  </Menu.Item>
                                  <Menu.Item
                                    icon={
                                      <IconSquareToggle size={16} stroke={1} />
                                    }
                                  >
                                    Rename
                                  </Menu.Item>
                                  <Menu.Divider />
                                  <Menu.Item
                                    icon={<IconTrash size={16} stroke={1} />}
                                    color="red"
                                  >
                                    Delete
                                  </Menu.Item>
                                </Menu.Dropdown>
                              </Menu>
                            </Group>
                          </Group>
                          <Paper bg="gray.0">
                            <Image
                              sx={{ margin: "auto 0" }}
                              height={100}
                              src={item.Thumbnail}
                              withPlaceholder
                              fit="contain"
                            />
                          </Paper>
                          <Tooltip label={item.Name}>
                            <Text fz="xs" truncate fw={500}>
                              {item.Name}
                            </Text>
                          </Tooltip>
                          <Group position="apart">
                            <IconPhoto size={16} stroke={1} />
                            <CopyButton value={item.Url}>
                              {({ copied, copy }) => (
                                <Tooltip
                                  label="copied"
                                  opened={copied}
                                  withArrow
                                >
                                  <ActionIcon onClick={copy}>
                                    <IconCopy size={16} />
                                  </ActionIcon>
                                </Tooltip>
                              )}
                            </CopyButton>
                          </Group>
                          <Group>
                            <Text fz="xs" color="dimmed">
                              Added <ReactTimeAgo date={item.CreatedAt} />
                            </Text>
                          </Group>
                        </Stack>
                      </Card>
                    </UnstyledButton>
                  </Paper>
                )}
              </Grid.Col>
            );
          })}
          {items.length === 0 && (
            <Grid.Col md={12}>
              <Text color="dimmed">No item found.</Text>
            </Grid.Col>
          )}
        </Grid>
      </Card>

      <Modal
        opened={folderModalOpened}
        size="lg"
        onClose={() => setFolderModalOpened(false)}
        closeOnClickOutside={false}
        sx={{ minHeight: 300 }}
        title={
          <Stack spacing="xs">
            <Text fw="bold" fz="sm">
              {selectedAsset?.Id ? "Edit folder" : "Add new folder"}
            </Text>
            <Divider variant="dashed" />
          </Stack>
        }
      >
        {folderModalOpened && (
          <FolderEditor
            id={selectedAsset?.Id}
            onClose={(reload: boolean) => {
              setFolderModalOpened(false);
              if (reload) loadAssets();
            }}
          />
        )}
      </Modal>

      <Modal
        opened={imageModalOpened}
        size="lg"
        onClose={() => setImageModalOpened(false)}
        closeOnClickOutside={false}
        sx={{ minHeight: 300 }}
        title={
          <Stack spacing="xs">
            <Text fw="bold" fz="sm">
              Add New Image
            </Text>
            <Divider variant="dashed" />
          </Stack>
        }
      >
        {imageModalOpened && (
          <ImageEditor
            onClose={(reload: boolean) => {
              setImageModalOpened(false);
              if (reload) loadAssets();
            }}
          />
        )}
      </Modal> */}
    </Layout.ContentLayout>
  );
};
