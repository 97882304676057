import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ArrowLeftShort,
  Bezier2,
  CalendarDate,
  Watch,
  Table,
  Image,
} from "@modir/icons";
import {
  useDataProvider,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@modir/core";

import { ModirBuilder } from "@modir/builder";
import {
  CurrentDate,
  Time,
  CurrencyTable,
  CloudImage,
} from "@components/custom";

import styled from "@emotion/styled";
import { screenService } from "@services/screen.service";
import { useAccount } from "@providers/AccountContext";
import { LoadingOverlay } from "@modir/ui-mantine";
import { Layout } from "@components/layouts";

const TopBarDiv = styled.div`
  display: flex;
  height: 50px;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
  padding: 5px;
  border-bottom: 1px solid #666;
  background-color: #2b2b2b;
`;

const BackButton = styled.button`
  color: inherit;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  flex: 0 0 auto;
  color: #666;
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: none;
  border-radius: 50%;
  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 30px;
    transition: none;
    user-select: none;
    flex-shrink: 0;
  }
`;

const SpaceNameDiv = styled.div`
  display: inline-block;

  span {
    border-radius: 5px;
    white-space: nowrap;
    padding: 6px;
    font-size: 12px;
    margin: 0px 7px;
    background-color: rgba(26, 115, 232, 0.1);
    color: rgb(26, 115, 232);

    svg {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 24px;
      transition: none;
      user-select: none;
      flex-shrink: 0;

      vertical-align: top;
      padding: 4px;
      border-radius: 5px;
    }
  }
`;

const ScreenNameDiv = styled.div`
  flex-grow: 1;
  margin-right: auto;
  display: flex;
  margin-right: 20px;
  margin-left: 10px;
  min-width: 150px;
  width: auto;
  margin-top: 2px;

  color: #666;
  cursor: text;
  display: inline-flex;
  font-size: 1rem;
  line-height: 1.1875em;
  align-items: center;

  input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    border-bottom: 1px solid transparent;
    transition: border-color 0.1s;
    outline: none;
  }
`;

export const Designer = () => {
  const dataProvider = useDataProvider();

  const { useParams } = useRouterContext();
  const { screenId, orgId } = useParams<any>();

  const _screenService = useMemo(
    () => screenService(dataProvider(), orgId),
    [dataProvider, orgId]
  );
  const { open: notify } = useNotification();

  const { push } = useNavigation();

  const [screen, setScreen] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadScreen = useCallback(async () => {
    const projectId = localStorage.getItem(`sg.app.currentProject`);
    if (projectId && screenId) {
      setLoading(true);
      _screenService
        .getById(projectId, screenId)
        .then((result) => {
          setScreen(result);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    loadScreen();
  }, []);

  const onSave = (contentStr: any) => {
    screen.DraftContent = contentStr;

    const projectId = localStorage.getItem(`sg.app.currentProject`);
    setLoading(true);
    _screenService
      .update(projectId!, screen)
      .then(() => {
        notify?.({
          message: "Screen has been saved successfully",
          type: "success",
        });
      })
      .catch((err) => {
        notify?.({
          message: "An error occured.",
          description: JSON.stringify(err),
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout.FullLayout>
      <LoadingOverlay visible={loading} />
      {screen && (
        <>
          <TopBarDiv>
            <BackButton
              type="button"
              onClick={() => {
                push(`/${orgId}/screens`);
              }}
            >
              <ArrowLeftShort />
            </BackButton>
            <SpaceNameDiv>
              <span>
                {screen.Name}
                <Bezier2 />
              </span>
            </SpaceNameDiv>
            <ScreenNameDiv>
              <input type="text" value={screen.Name} />
            </ScreenNameDiv>
          </TopBarDiv>
          <ModirBuilder
            jsonContent={screen.DraftContent}
            onSave={(str: string) => {
              onSave(str);
            }}
            onPreview={() => {
              window.open(`/${orgId}/screens/preview/${screen.Id}`, "_blank");
            }}
            customResolver={{ CurrentDate, Time, CurrencyTable, CloudImage }}
            customComponents={[
              {
                name: "Date",
                icon: <CalendarDate />,
                element: <CurrentDate fontSize="16" textAlign="left" />,
              },
              {
                name: "Time",
                icon: <Watch />,
                element: <Time fontSize="16" textAlign="left" />,
              },
              {
                name: "Rates",
                icon: <Table />,
                element: <CurrencyTable fontSize="16" />,
              },
              {
                name: "Image+",
                icon: <Image />,
                element: <CloudImage />,
              },
            ]}
          />
        </>
      )}
    </Layout.FullLayout>
  );
};

Designer.showSidebar = false;
Designer.hasContentArea = false;
