import { IDataContextProvider } from "@modir/core";

export const projectService = (dataProvider: IDataContextProvider, orgId: string) => ({

    listAll: async () => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/${orgId}/projects`,
        }).then((result) => {
            return result?.data;
        });
    },

    getById: async (id: string) => {
        return dataProvider.getOne({ resource: `${orgId}/projects`, id }).then((result) => {
            return result?.data;
        });
    },

    create: async (entity: any) => {
        return dataProvider
            .create({ resource: `${orgId}/projects`, variables: entity })
            .then((result) => {
                return result?.data;
            });
    },


    update: async (entity: any) => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "put",
            url: `${baseUrl}/${orgId}/projects`,
            payload: entity
        }).then((result) => {
            return result?.data;
        });
    },


    delete: async (id: string) => {
        return dataProvider
            .deleteOne({ resource: `${orgId}/projects`, id })
            .then((result) => {
                return result?.data;
            });
    },

});
