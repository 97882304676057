import { IDataContextProvider } from "@modir/core";

export const assetsService = (dataProvider: IDataContextProvider, orgId: string) => ({

    listAll: async (path: string) => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/${orgId}/assets?path=${path}`,
        }).then((result) => {
            return result?.data;
        });
    },

    createFolder: async (entity: any) => {
        return dataProvider
            .create({ resource: `${orgId}/assets`, variables: entity })
            .then((result) => {
                return result?.data;
            });
    },

    addMedia: async (entity: any) => {
        return dataProvider
            .create({ resource: `${orgId}/assets/media`, variables: entity })
            .then((result) => {
                return result?.data;
            });
    },

    uploadMedia: async (url: string, variables: any) => {
        // let form_data = new FormData();
        // if (variables) {
        //     for (const field of Object.keys(variables))
        //         form_data.append(field, variables[field]);
        // }

        return await fetch(
            url, {
            method: "PUT",
            body: variables.file,
            headers: {
                "Content-Type": variables.file.type,
                "Accept": "*/*",
            }
        }
        ).then((result) => {
            return result;
        });
    }
});
