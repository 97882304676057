import React, { useEffect, useState } from "react";
import {
  UserComponent,
  useNode,
  useEditor,
  BuilderBlock,
  StyleSettings,
  IBuilderBlockProps,
  defaultStyleProps,
  defaultCssStyles,
} from "@modir/builder";
import currency from "currency.js";
import { CurrencyTableSettings } from "./CurrencyTableSettings";
import styled from "@emotion/styled";
import { useDataProvider, useRouterContext } from "@modir/core";
import { rateService } from "@services/rate.service";
import Flag from "react-world-flags";
import { IconCashBanknote } from "@tabler/icons-react";
import { Group, LoadingOverlay } from "@modir/ui-mantine";
import { useEffectOnce } from "@utils/useEffectOnce";
import { useCallback } from "react";
import { useRef } from "react";

export interface ICurrencyTableProps extends Omit<IBuilderBlockProps, "color"> {
  fontSize: string;
  textAlign: string;
  fontWeight: string;
  headerColor: Record<"r" | "g" | "b" | "a", string>;
  headerBgColor: Record<"r" | "g" | "b" | "a", string>;

  rowColor: Record<"r" | "g" | "b" | "a", string>;
  rowBgColor: Record<"r" | "g" | "b" | "a", string>;
  altRowColor: Record<"r" | "g" | "b" | "a", string>;
  altRowBgColor: Record<"r" | "g" | "b" | "a", string>;

  borderColor: Record<"r" | "g" | "b" | "a", string>;

  headerGroupTitle: string;

  shadow: number;
}

const WrapperDiv = styled.div<any>`
  ${defaultCssStyles}
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: ${(props) => `${props.fontWeight}`};
  text-align: ${(props) => `${props.textAlign}`};

  table {
    width: 100%;
    th {
      color: ${(props) =>
        props.headerColor ? `rgba(${Object.values(props.headerColor)})` : null};
      background-color: ${(props) =>
        props.headerBgColor
          ? `rgba(${Object.values(props.headerBgColor)})`
          : null};
    }
    .center {
      text-align: center;
    }

    tbody tr {
      color: ${(props) =>
        props.rowColor ? `rgba(${Object.values(props.rowColor)})` : null};
      background-color: ${(props) =>
        props.rowBgColor ? `rgba(${Object.values(props.rowBgColor)})` : null};
    }

    tbody tr:nth-child(even) {
      color: ${(props) =>
        props.altRowColor ? `rgba(${Object.values(props.altRowColor)})` : null};
      background-color: ${(props) =>
        props.altRowBgColor
          ? `rgba(${Object.values(props.altRowBgColor)})`
          : null};
    }

    td,
    th {
      padding: 10px;
      border-right: ${(props) =>
        props.borderColor
          ? `solid 1px rgba(${Object.values(props.borderColor)})`
          : null};
    }

    td:last-of-type,
    th:last-of-type {
      border-right: none;
    }
    .header-group {
      border-bottom: ${(props) =>
        props.borderColor
          ? `solid 1px rgba(${Object.values(props.borderColor)})`
          : null};
    }
    .no-border {
      border: 1px solid transparent !important;
    }
  }
`;

const parseData = (response: any) => {
  return {
    id: response.id,
    createdOn: response.created,
    baseCurrency: response.data.baseCurrency,
    foreignCurrency: response.data.foreignCurrency,
    title: response.data.title,
    status: response.data.status,
    buy: response.data.buy,
    buyEnabled: response.data.buyEnabled,
    sell: response.data.sell,
    sellEnabled: response.data.sellEnabled,
    rate: response.data.rate,
    rateEnabled: response.data.rateEnabled,
    flagUrl: response.data.flagUrl,
    currencyFormat: response.data.currencyFormat,
    displayOrder: response.data.displayOrder,
  };
};

export const CurrencyTable: UserComponent<Partial<ICurrencyTableProps>> = ({
  fontSize,
  fontWeight,
  textAlign,
  headerColor,
  headerBgColor,
  rowColor,
  rowBgColor,
  altRowColor,
  altRowBgColor,
  borderColor,
  headerGroupTitle,

  shadow,
  ...rest
}) => {
  const {
    connectors: { connect },
    setProp,
  } = useNode();

  const { useParams } = useRouterContext();
  const { orgId } = useParams<any>();

  const dataProvider = useDataProvider();
  const _rateService = rateService(dataProvider(), orgId);

  const ref = useRef<any>(null);

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadRates = useCallback(async () => {
    setLoading(true);
    const items: any = await _rateService.listPublic();

    setRates(items);
    setLoading(false);
  }, []);

  useEffect(() => {
    ref.current = setInterval(loadRates, 1 * 60 * 1000);
    loadRates();
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  return (
    <BuilderBlock {...rest}>
      <WrapperDiv
        ref={connect as any}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
        headerColor={headerColor}
        headerBgColor={headerBgColor}
        rowColor={rowColor}
        rowBgColor={rowBgColor}
        altRowColor={altRowColor}
        altRowBgColor={altRowBgColor}
        borderColor={borderColor}
        shadow={shadow}
      >
        {/* <LoadingOverlay visible={loading} /> */}
        <table>
          <thead>
            {headerGroupTitle && (
              <tr>
                <th rowSpan={2}>CURRENCY</th>
                <th className="center header-group" colSpan={2}>
                  {headerGroupTitle}
                </th>
              </tr>
            )}
            <tr>
              {!headerGroupTitle && <th>CURRENCY</th>}
              <th className="center">BUY</th>
              <th className="center">SELL</th>
            </tr>
          </thead>
          <tbody>
            {rates.map((rate: any, index) => {
              return (
                <tr key={index}>
                  <td className="d-flex align-items-center">
                    <Group>
                      <Flag
                        code={rate.CountryCode?.toUpperCase()}
                        width={50}
                        fallback={<IconCashBanknote size={50} />}
                      />
                      {rate.Title}
                    </Group>
                  </td>
                  <td className="center">
                    {currency(rate.Buy?.Rate, {
                      symbol: "",
                      precision: rate.Settings.Round ?? 4,
                    }).format()}
                  </td>
                  <td className="center">
                    {currency(rate.Sell?.Rate, {
                      symbol: "",
                      precision: rate.Settings.Round ?? 4,
                    }).format()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </WrapperDiv>
    </BuilderBlock>
  );
};

CurrencyTable.craft = {
  related: {
    toolbar: CurrencyTableSettings,
    styles: StyleSettings,
  },
};

CurrencyTable.defaultProps = { ...defaultStyleProps };
