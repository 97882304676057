import React, { PropsWithChildren } from "react";
import {
  QuestionCircle,
  PersonCircle,
  Collection,
  CurrencyExchange,
} from "@modir/icons";
import {
  LogoContent,
  SidebarContent,
  SidebarDiv,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarItemIcon,
  SidebarItemLabel,
} from "./Sidebar.styles";
import logo from "../../assets/images/logo.svg";
import {
  useRouterContext,
  useLogout,
  useAuthenticated,
  useNavigation,
} from "@modir/core";
import {
  ActionIcon,
  Anchor,
  Avatar,
  Divider,
  Flex,
  Group,
  Menu,
  Text,
  Title,
} from "@modir/ui-mantine";
import { useAuth } from "@providers/auth-cognito";
import { useAccount } from "@providers/AccountContext";
import {
  IconCurrency,
  IconDashboard,
  IconFiles,
  IconLayoutDashboard,
} from "@tabler/icons-react";

export interface ISidebarProps {}

export const Sidebar: React.FC<PropsWithChildren<ISidebarProps>> = ({
  children,
}) => {
  const { Link } = useRouterContext();

  const { push } = useNavigation();
  const { mutate: logout } = useLogout();
  const { signOut } = useAuth();

  const { data: user } = useAuthenticated();
  const { currentOrganization } = useAccount();

  return (
    <SidebarDiv className="sidebar-nav">
      <SidebarContent>
        <SidebarHeader>
          <>
            <Group position="apart">
              <Anchor
                component={Link}
                to={`/${currentOrganization?.OrganizationId}`}
              >
                <Flex align="center" gap="xs">
                  <img src={logo} alt="MoneyStudio" width={40} height={40} />
                  <LogoContent>
                    <Title order={6} color="gray.3">
                      SignagePlus™
                    </Title>
                  </LogoContent>
                </Flex>
              </Anchor>
              {user && (
                <Menu>
                  <Menu.Target>
                    <ActionIcon>
                      <Avatar src={user?.picture} size="sm" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>
                      <Text>Signed in as</Text>
                      <Text fz="xs">{user?.name}</Text>
                    </Menu.Label>
                    <Menu.Item>
                      <Anchor component={Link} to="/account/user">
                        User settings
                      </Anchor>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        signOut().finally(() => {
                          window.location.href = "/login";
                        });
                      }}
                    >
                      Sign out
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
          </>

          <Divider my="sm" color="gray.7" />

          <Anchor
            component={Link}
            to={`/${currentOrganization?.OrganizationId}/projects`}
          >
            <SidebarItem>
              <SidebarItemIcon>
                <Collection />
              </SidebarItemIcon>
              <SidebarItemLabel>Projects</SidebarItemLabel>
            </SidebarItem>
          </Anchor>
          <Anchor
            component={Link}
            to={`/${currentOrganization?.OrganizationId}/assets`}
          >
            <SidebarItem>
              <SidebarItemIcon>
                <IconFiles size={16} stroke={1} />
              </SidebarItemIcon>
              <SidebarItemLabel>Assets</SidebarItemLabel>
            </SidebarItem>
          </Anchor>
          <Anchor
            component={Link}
            to={`/${currentOrganization?.OrganizationId}/widgets`}
          >
            <SidebarItem>
              <SidebarItemIcon>
                <IconLayoutDashboard size={16} stroke={1} />
              </SidebarItemIcon>
              <SidebarItemLabel>Widgets</SidebarItemLabel>
            </SidebarItem>
          </Anchor>
        </SidebarHeader>

        <SidebarFooter>
          <Anchor component={Link} to="/help">
            <SidebarItem>
              <SidebarItemIcon>
                <QuestionCircle />
              </SidebarItemIcon>
              <SidebarItemLabel>Help</SidebarItemLabel>
            </SidebarItem>
          </Anchor>
          {/* <SidebarItem>
            <SidebarItemIcon>
              <MoonFill />
            </SidebarItemIcon>
            <SidebarItemLabel>Toggle Dark Mode</SidebarItemLabel>
          </SidebarItem> */}
          <Anchor component={Link} to="/account/organization">
            <SidebarItem>
              <SidebarItemIcon>
                <PersonCircle />
              </SidebarItemIcon>
              <SidebarItemLabel>Account Settings</SidebarItemLabel>
            </SidebarItem>
          </Anchor>
        </SidebarFooter>
      </SidebarContent>
    </SidebarDiv>
  );
};
