import { IDataContextProvider } from "@modir/core";

export const accountService = (dataProvider: IDataContextProvider) => ({

    getCurrentUser: async () => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/account`,
        }).then((result) => {
            return result?.data;
        });
    },

    createOrganization: async (entity: any) => {
        return dataProvider
            .create({ resource: `account`, variables: entity })
            .then((result) => {
                return result?.data;
            });
    },


});
