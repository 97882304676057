import {
  ILoginPageProps,
  useLogin,
  useRouterContext,
  useTranslate,
} from "@modir/core";
import {
  Alert,
  Anchor,
  BackgroundImage,
  BoxProps,
  Button,
  Card,
  CardProps,
  Flex,
  Group,
  Image,
  isEmail,
  isNotEmpty,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
  useForm,
} from "@modir/ui-mantine";

import logo from "../../assets/images/logo.svg";
import bg from "../../assets/images/bg10.jpeg";
import { useState } from "react";
import { IconAlertCircle } from "@tabler/icons-react";
import { useAuth } from "@providers/auth-cognito";
import axios from "axios";

type ILoginProps = ILoginPageProps<BoxProps, CardProps, any>;

export const Login = ({ formProps }: any) => {
  //const { mutate: login, isLoading } = useLogin<ILoginForm>();
  const { onSubmit: onSubmitProp, ...useFormProps } = formProps || {};
  const translate = useTranslate();
  const { Link } = useRouterContext();

  const [error, setError] = useState<string>();
  const [errorType, setErrorType] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validate: {
      email: isEmail(
        translate("pages.login.errors.validEmail", "Enter a valid email")
      ),
      password: isNotEmpty(
        translate("pages.login.errors.password", "Enter your password")
      ),
    },
  });

  const { mutate: login, isLoading } = useLogin<any>();
  const { signIn, isAuthenticating } = useAuth();

  const onSubmit = (values: any) => {
    setLoading(true);
    // login(values, {
    //   onError: (error) => {
    //     setError(error.message);
    //   },
    // });

    signIn(values)
      .then(() => {
        localStorage.removeItem("ms.cb.org");
        window.location.href = "/";
      })
      .catch((error) => {
        setErrorType(error.code);
        setError(error.message);
      })
      .finally(() => setLoading(false));
  };
  return (
    <BackgroundImage src={bg}>
      <Flex mih="100vh" align="center" justify="center">
        <Card withBorder bg="gray.0" w={500}>
          <Card.Section p="lg" withBorder>
            <Group position="center">
              <Image src={logo} width={50} />
              <div>
                <Title order={4}>SignagePlus™</Title>
                <Title order={6} color="gray.6" fw={500}>
                  by RimaSoft
                </Title>
              </div>
            </Group>
          </Card.Section>
          <Card.Section>
            <form onSubmit={form.onSubmit(onSubmit)}>
              <Paper radius={0} p={30}>
                <Text size="lg" fw={600} mb="lg">
                  Sign in to SignagePlus™ app
                </Text>
                {error && (
                  <Alert
                    color="red"
                    mb="sm"
                    icon={<IconAlertCircle size={16} />}
                  >
                    <Group position="apart">
                      {error}
                      {errorType === "UserNotConfirmedException" && (
                        <Anchor component={Link} to="/verify">
                          Verify your account
                        </Anchor>
                      )}
                    </Group>
                  </Alert>
                )}
                <TextInput
                  label="Email address"
                  size="md"
                  autoComplete="value"
                  {...form.getInputProps("email")}
                />
                <PasswordInput
                  label="Password"
                  mt="md"
                  size="md"
                  {...form.getInputProps("password")}
                />

                <Button
                  fullWidth
                  mt="xl"
                  size="md"
                  type="submit"
                  loading={loading}
                >
                  Login
                </Button>

                <Text align="center" mt="md">
                  Don&apos;t have an account?{" "}
                  <Anchor component={Link} weight={700} to="/signup">
                    Register
                  </Anchor>
                </Text>
              </Paper>
            </form>
          </Card.Section>
        </Card>
      </Flex>
    </BackgroundImage>
  );
};
