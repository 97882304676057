import { useRouterContext } from "@modir/core";

import { Currencies } from "./CurrencyRates";

export const WidgetSettings = () => {
  const { useParams } = useRouterContext();

  const { widgetType } = useParams<any>();

  return <>{widgetType === "CurrencyRates" && <Currencies />}</>;
};
