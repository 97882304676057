import { IDataContextProvider } from "@modir/core";

export const marketRateService = (dataProvider: IDataContextProvider) => ({

    getMarketRates: async (baseCurrency: string) => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/latest`,
            query: { base: baseCurrency },
            headers: {
                "x-api-key": process.env.REACT_APP_MARKET_RATE_API_KEY,
            },
        }).then((result) => {
            return result?.data;
        });
    },

});
