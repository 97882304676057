import React from "react";

import {
  ToolbarSection,
  ToolbarItem,
  ToolbarRadio,
  ToolbarSelect,
} from "@modir/builder";

export const TimeSettings = () => {
  return (
    <React.Fragment>
      <ToolbarSection title="Typography" defaultOpen={true}>
        <ToolbarItem
          full={true}
          propKey="fontSize"
          label="Font size"
          type="slider"
          valueSuffix="px"
        />
        <ToolbarItem
          label="Align"
          propKey="textAlign"
          type="select"
          options={[
            { label: "Left", value: "left" },
            { label: "Center", value: "center" },
            { label: "Right", value: "right" },
          ]}
        />
        <ToolbarItem
          label="Weight"
          propKey="fontWeight"
          type="select"
          options={[
            { label: "Regular", value: "400" },
            { label: "Medium", value: "500" },
            { label: "Bold", value: "700" },
          ]}
        />
      </ToolbarSection>
      <ToolbarSection title="Appearance">
        <ToolbarItem
          full={true}
          propKey="color"
          type="color"
          label="Text color"
        />
        {/* <ToolbarItem
          full={true}
          propKey="shadow"
          type="slider"
          label="Shadow"
        /> */}
      </ToolbarSection>
    </React.Fragment>
  );
};
