import React, { PropsWithChildren } from "react";
import { Global, css } from "@emotion/react";
import { ILayoutProps } from "@modir/core";
import { LayoutDiv, MainContainerDiv, MainContentDiv } from "./Layout.styles";
import { Sidebar } from "./Sidebar";
import { AccountProvider } from "@providers/AccountContext";

export interface IAdminLayoutProps extends ILayoutProps {
  showSidebar?: boolean;
  hasContentArea?: boolean;
}

const FullLayout = ({ children }: any) => {
  return <>{children}</>;
};

const ContentLayout = ({ children }: any) => {
  return (
    <LayoutDiv>
      <Sidebar />
      <MainContainerDiv>
        <div>
          <div className="main-inner">
            <MainContentDiv>{children}</MainContentDiv>
          </div>
        </div>
      </MainContainerDiv>
    </LayoutDiv>
  );
};

export const Layout = ({ children }: any) => {
  return (
    <>
      <Global
        styles={css`
          body,
          html {
            width: 100vw;
            height: 100%;
            overflow: hidden;
          }

          body {
            margin: 0;
            background-color: #fff;
          }

          #__next {
            background: #fff;
            height: 100%;
          }
        `}
      />
      <AccountProvider>{children}</AccountProvider>
    </>
  );
};

Layout.ContentLayout = ContentLayout;
Layout.FullLayout = FullLayout;
