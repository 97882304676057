import React from "react";
import {
  UserComponent,
  useNode,
  useEditor,
  BuilderBlock,
  Container,
  StyleSettings,
  IBuilderBlockProps,
  defaultStyleProps,
  defaultCssStyles,
} from "@modir/builder";
import moment from "moment";
import { CurrentDateSettings } from "./CurrentDateSettings";
import styled from "@emotion/styled";

export interface ICurrentDateProps extends Omit<IBuilderBlockProps, "color"> {
  fontSize: string;
  textAlign: string;
  fontWeight: string;
  //color: Record<"r" | "g" | "b" | "a", string>;
  shadow: number;
}

const DateDiv = styled.div<any>`
  ${defaultCssStyles}
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: ${(props) => `${props.fontWeight}`};
  text-align: ${(props) => `${props.textAlign}`};
  color: ${(props) =>
    props.color ? `rgba(${Object.values(props.color)})` : null};
`;

export const CurrentDate: UserComponent<Partial<ICurrentDateProps>> = ({
  fontSize,
  fontWeight,
  textAlign,
  //color,
  shadow,
  ...rest
}) => {
  const {
    connectors: { connect },
    setProp,
  } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <BuilderBlock {...rest}>
      <DateDiv
        ref={connect as any}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
        //color={color}
        shadow={shadow}
      >
        {moment(new Date()).format("D MMM yyyy")}
      </DateDiv>
    </BuilderBlock>
  );
};

CurrentDate.craft = {
  related: {
    toolbar: CurrentDateSettings,
    styles: StyleSettings,
  },
};

CurrentDate.defaultProps = { ...defaultStyleProps };
