import { IDataContextProvider } from "@modir/core";

export const rateService = (dataProvider: IDataContextProvider, orgId: string) => ({

    listAll: async () => {
        const baseUrl = dataProvider.getApiUrl();


        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/${orgId}/widget/currencyboard/rates`,
        }).then((result) => {
            return result?.data;
        });
    },

    listPublic: async () => {
        const baseUrl = dataProvider.getApiUrl();


        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/${orgId}/widget/currencyboard/publicrates`,
        }).then((result) => {
            return result?.data;
        });
    },

    getById: async (id: string) => {


        return dataProvider.getOne({ resource: `${orgId}/widget/currencyboard/rates`, id }).then((result) => {
            return result?.data;
        });
    },

    create: async (entity: any) => {


        return dataProvider
            .create({ resource: `${orgId}/widget/currencyboard/rates`, variables: entity })
            .then((result) => {
                return result?.data;
            });
    },

    update: async (entity: any) => {
        const baseUrl = dataProvider.getApiUrl();


        return await dataProvider.custom?.({
            method: "put",
            url: `${baseUrl}/${orgId}/widget/currencyboard/rates`,
            payload: entity
        }).then((result) => {
            return result?.data;
        });
    },

    delete: async (id: string) => {


        return dataProvider
            .deleteOne({ resource: `${orgId}/widget/currencyboard/rates`, id })
            .then((result) => {
                return result?.data;
            });
    },

});
