import { IDataContextProvider } from "@modir/core";

export const screenService = (dataProvider: IDataContextProvider, orgId: string) => ({

    listAll: async (projectId: string) => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/${orgId}/projects/${projectId}/screens`,
        }).then((result) => {
            return result?.data;
        });
    },

    listCasting: async (orgId: string, projectSlug: string) => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "get",
            url: `${baseUrl}/${orgId}/cast/${projectSlug}`,
        }).then((result) => {
            return result?.data;
        });
    },

    getById: async (projectId: string, id: string) => {

        return dataProvider.getOne({ resource: `${orgId}/projects/${projectId}/screens`, id }).then((result) => {
            return result?.data;
        });
    },

    create: async (projectId: string, entity: any) => {

        return dataProvider
            .create({ resource: `${orgId}/projects/${projectId}/screens`, variables: entity })
            .then((result) => {
                return result?.data;
            });
    },

    update: async (projectId: string, entity: any) => {
        const baseUrl = dataProvider.getApiUrl();

        return await dataProvider.custom?.({
            method: "post",
            url: `${baseUrl}/${orgId}/projects/${projectId}/screens`,
            payload: entity
        }).then((result) => {
            return result?.data;
        });
    },

    delete: async (projectId: string, id: string) => {

        return dataProvider
            .deleteOne({ resource: `${orgId}/projects/${projectId}/screens`, id })
            .then((result) => {
                return result?.data;
            });
    },

});
