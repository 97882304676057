import styled from "@emotion/styled";

export const PageTitleDiv = styled.div`
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(68, 68, 68);
    align-self: stretch;

    svg {
        font-size: 60px;
        opacity: 0.5;
        margin-left: -10px;
        margin-right: -5px;
    }
`

export const PageTitleContentDiv = styled.div`
    margin-left: 25px;
`

export const PageTitleTitleDiv = styled.div`
    font-size: 32px;
`

export const PageTitleDescription = styled.div`
    color: rgb(119, 119, 119);
    margin-top: 10px;
`

export const PageTitleActions = styled.div`
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: none;
    border-radius: 50%;
    margin-left: auto;
`