import React from "react";
import dataProvider from "@modir/data-rest";
import routerProvider from "@modir/react-router";
import { IAuthProvider, IHttpError } from "@modir/core";
import axios from "axios";
import { ModirWrapper } from "./ModirWrapper";
import { Layout } from "@components";
import {
  Projects,
  Screens,
  Designer,
  Currencies,
  ProjectPreviewPage,
  ScreenPreview,
  Login,
  ProjectCastPage,
  OrganizationEditor,
  Dashboard,
  Assets,
  Widgets,
  WidgetSettings,
} from "@pages";
import { LoadingOverlay } from "@modir/ui-mantine";

import { useAuth } from "@providers/auth-cognito/useAuth";
import { Signup } from "@pages/auth/Signup";
import { Verify } from "@pages/auth/Verify";
import en from "javascript-time-ago/locale/en.json";
import TimeAgo from "javascript-time-ago";
TimeAgo.addDefaultLocale(en);

export const App: React.FC = () => {
  const API_URL = process.env.REACT_APP_CB_API_URL!;
  const MARKET_RATE_URL = process.env.REACT_APP_MARKET_RATE_API_URL!;

  const {
    signIn,
    signOut,
    isAuthenticated,
    isAuthenticating,
    getAccessToken,
    user,
  } = useAuth();

  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error: any) => {
      if (401 === error?.response?.status) {
        //loginWithRedirect();
        window.location.href = "/login";
        return Promise.resolve();
      } else {
        const customError: IHttpError = {
          ...error,
          message: error.response?.data?.message,
          statusCode: error.response?.status,
        };
        return Promise.reject(customError);
      }
    }
  );

  if (isAuthenticating) {
    return <LoadingOverlay visible={true} />;
  }

  const authProvider: IAuthProvider = {
    login: async (params) => {
      signIn({
        email: params.email,
        password: params.password,
      })
        .then((result) => {
          return Promise.resolve();
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    logout: () => {
      signOut();
      return Promise.resolve("/");
    },
    checkError: (error) => {
      debugger;
      if (error.status === 401) {
        signOut();
        return Promise.resolve();
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      if (isAuthenticated) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: () => {
      if (user) {
        return Promise.resolve({
          ...user,
          avatar: user.picture,
        });
      } else {
        return Promise.reject();
      }
    },
  };

  getAccessToken()
    .then((token) => {
      if (token) {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
      }
    })
    .catch((err) => {
      axios.defaults.headers.common = {};
    });
  return (
    <ModirWrapper
      warnWhenUnsavedChanges={true}
      authProvider={authProvider}
      dataProvider={{
        default: dataProvider(API_URL, axios),
        market: dataProvider(MARKET_RATE_URL),
      }}
      DashboardPage={() => <h1>Dashboard</h1>}
      Layout={Layout}
      LoginPage={Login}
      routerProvider={{
        ...routerProvider,
        routes: [
          { path: "/login", element: <Login />, private: false },
          { path: "/signup", element: <Signup />, private: false },
          { path: "/verify", element: <Verify />, private: false },
          {
            path: "/organizations/new",
            element: <OrganizationEditor />,
            layout: true,
            private: true,
          },
          {
            path: "/:orgId",
            element: <Dashboard />,
            layout: true,
            private: true,
          },
          {
            path: "/:orgId/assets",
            element: <Assets />,
            layout: true,
            private: true,
          },
          {
            path: "/:orgId/cast/:slug",
            element: <ProjectCastPage />,
            private: false,
          },
          {
            path: "/:orgId/projects",
            element: <Projects />,
            layout: true,
            private: true,
          },
          {
            path: "/:orgId/projects/preview/:projectId",
            element: <ProjectPreviewPage />,
            private: true,
          },
          {
            path: "/:orgId/screens/preview/:screenId",
            element: <ScreenPreview />,
            private: true,
          },
          {
            path: "/:orgId/screens",
            element: <Screens />,
            layout: true,
            private: true,
          },
          {
            path: "/:orgId/currencies",
            element: <Currencies />,
            layout: true,
            private: true,
          },
          {
            path: "/:orgId/designer/:screenId",
            element: <Designer />,
            private: true,
            layout: true,
          },

          {
            path: "/:orgId/widgets",
            element: <Widgets />,
            private: true,
            layout: true,
          },

          {
            path: "/:orgId/widgets/:widgetType",
            element: <WidgetSettings />,
            private: true,
            layout: true,
          },
        ] as typeof routerProvider.routes,
      }}
    />
  );
};
