import { Tabs } from "@mantine/core";
import { ModirAssets } from "@pages/assets";

interface IImageModalProps {
  onClose?: (image: any) => void;
}
export const ImageModal = ({ onClose }: IImageModalProps) => {
  return (
    <Tabs defaultValue="assets">
      <Tabs.List>
        <Tabs.Tab value="assets">Your photos</Tabs.Tab>
        <Tabs.Tab value="stock">Free stock photos</Tabs.Tab>
        <Tabs.Tab value="url">Load from URL</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="assets" pt="xs">
        <ModirAssets
          onSelect={(image) => {
            onClose?.(image);
          }}
        />
      </Tabs.Panel>

      <Tabs.Panel value="stock" pt="xs">
        Messages tab content
      </Tabs.Panel>

      <Tabs.Panel value="url" pt="xs">
        Settings tab content
      </Tabs.Panel>
    </Tabs>
  );
};
