import { Layout, PageTitle } from "@components/layouts";
import { IconHome2 } from "@tabler/icons-react";

export const Dashboard = () => {
  return (
    <Layout.ContentLayout>
      <PageTitle
        title="Dashboard"
        description="Welcome to SignagPlus!"
        icon={<IconHome2 size={60} stroke={1} />}
      />
    </Layout.ContentLayout>
  );
};
