import { ToolbarSection, ToolbarItem } from "@modir/builder";
import { ToolbarItem as TbItem } from "..";

export const CloudImageSettings = () => {
  return (
    <>
      <ToolbarSection title="Options" defaultOpen={true}>
        <TbItem
          full={true}
          propKey="imageUrl"
          label="Image URL:"
          type="image"
        />
        <ToolbarItem
          label="Image fit"
          propKey="imageFit"
          type="select"
          options={[
            { label: "Cover", value: "cover" },
            { label: "Contain", value: "contain" },
          ]}
        />
        <ToolbarItem
          label="Image position"
          propKey="imagePosition"
          type="select"
          options={[
            { label: "Top", value: "center top" },
            { label: "Center", value: "center center" },
            { label: "Bottom", value: "center bottom" },
            { label: "Left", value: "left center" },
            { label: "Right", value: "right center" },
            { label: "Top Left", value: "left top" },
            { label: "Top Right", value: "right top" },
            { label: "Bottom Left", value: "left bottom" },
            { label: "Bottom Right", value: "right bottom" },
          ]}
        />
      </ToolbarSection>
    </>
  );
};
