import { useCallback, useState, useEffect } from "react";
import { useDataProvider, useNotification } from "@modir/core";
import {
  Button,
  Group,
  isNotEmpty,
  LoadingOverlay,
  Stack,
  Textarea,
  TextInput,
  useForm,
} from "@modir/ui-mantine";
import { accountService } from "@services";
import { Layout, PageTitle } from "@components/layouts";
import { IconBuilding, IconBuildingArch } from "@tabler/icons-react";

export const OrganizationEditor = (props: any) => {
  const { onClose, id } = props;
  const dataProvider = useDataProvider();

  const _accountService = accountService(dataProvider());
  const { open: notify } = useNotification();
  const form = useForm({
    initialValues: {
      Name: "",
      Slug: "",
      Description: "",
    },
    validate: {
      Name: isNotEmpty("This field is required."),
    },
    transformValues: (values) => ({
      ...values,
      Slug: (values.Name as any).replace(/[^a-z0-9_-]/gi, "-").toLowerCase(),
    }),
  });

  const [model, setModel] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    _accountService
      .createOrganization(data)
      .then(() => {
        notify?.({
          message: "New organization has been created successfully",
          type: "success",
        });

        window.location.href = "/";
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout.ContentLayout>
      <PageTitle
        title="Create a new organization"
        description="Organizations are privately shared spaces for teams to collaborate on infrastructure"
        icon={<IconBuilding size={70} />}
      />

      <LoadingOverlay visible={loading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <TextInput
            label="Name:"
            {...form.getInputProps("Name")}
            placeholder="Name"
          />

          <TextInput
            label="Unique url:"
            readOnly
            value={`www.signageplus.app/${
              (form.values.Name as any)
                ?.replace(/[^a-z0-9_-]/gi, "-")
                .toLowerCase() ?? ""
            }`}
          />

          <Textarea
            label="Description:"
            {...form.getInputProps("Description")}
            placeholder="Description"
            sx={{ height: 150 }}
          />
        </Stack>
        <Group>
          <Button
            variant="subtle"
            onClick={() => {
              onClose?.();
            }}
            className="btn btn-light"
          >
            Cancel
          </Button>
          <Button type="submit" variant="filled">
            Create
          </Button>
        </Group>
      </form>
    </Layout.ContentLayout>
  );
};
