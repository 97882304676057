import React, { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { LoadingOverlay } from "@modir/ui-mantine";

export interface IAuthContextType {
  user: any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  unverifiedAccount: { email: string; password: string };
  signIn: (p: { email: string; password: string }) => Promise<any>;
  signOut: () => Promise<any>;
  signUp: (p: {
    email: string;
    password: string;
    given_name: string;
    family_name: string;
    mobile: string;
  }) => Promise<any>;
  confirmAccount: (p: { email: string; code: string }) => Promise<any>;
  resendSignupCode: (p: { email: string }) => Promise<any>;
  getAccessToken: () => Promise<any>;
}

// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  unverifiedAccount: {
    email: "",
    password: "",
  },
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  confirmAccount: async () => {},
  resendSignupCode: async () => {},
  getAccessToken: async () => {},
});

interface IAuthProviderProps {
  children: React.ReactNode;
}

// Create a provider for components to consume and subscribe to changes
export const CognitoAuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: "",
    password: "",
  });

  /**
   * fetch currently logged-in user using AWS Auth library
   * @returns {Promise<void>}
   */
  const fetchAuthUser = async () => {
    try {
      const fetchedUser = await Auth.currentAuthenticatedUser();
      setIsAuthenticating(false);
      setUser(fetchedUser);
    } catch (err) {
      setIsAuthenticating(false);
      setUser(null);
    }
  };

  useEffect(() => {
    fetchAuthUser();

    // listening for auth change events
    const authListener = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        // console.log("Auth Status Changed Event: ", event);
        // console.log("Auth Status Changed Data: ", data);
        switch (event) {
          case "signIn":
            await fetchAuthUser();
            break;
          case "signOut":
            setUser(null);
            break;
          case "signIn_failure":
          case "signUp_failure":
            if (user) {
              setUser(null);
            }
            break;
          case "signUp":
          case "forgotPassword":
          case "forgotPasswordSubmit":
          case "forgotPasswordSubmit_failure":
          case "forgotPassword_failure":
            break;
          default:
            await fetchAuthUser();
        }
      }
    );

    // cleanup
    return () => {
      authListener();
    };
  }, []);

  /**
   * log user in
   * @param email
   * @param password
   */
  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    return Auth.signIn({ username: email, password });
  };

  /**
   * create new user account
   * @param email
   * @param password
   * @param firstName
   * @param lastName
   */
  const signUp = async ({
    email,
    password,
    given_name,
    family_name,
    mobile,
  }: {
    email: string;
    password: string;
    given_name: string;
    family_name: string;
    mobile: string;
  }) => {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        phone_number: mobile,
        given_name: given_name,
        family_name: family_name,
      },
    })
      .then((result) => {
        setUnverifiedAccount({ email, password });
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  /**
   * confirm account using code
   * @param confirmCode
   * @returns {Promise<any>}
   */
  const confirmAccount = ({ email, code }: { email: string; code: string }) => {
    const username =
      unverifiedAccount.email !== "" ? unverifiedAccount.email : email;

    return Auth.confirmSignUp(username, code);
    // await signIn({
    //   email: username,
    //   password: unverifiedAccount?.password,
    // });
  };

  /**
   * request new code
   * @param confirmCode
   * @returns {Promise<any>}
   */
  const resendSignupCode = ({ email }: { email: string }) => {
    const username =
      unverifiedAccount.email !== "" ? unverifiedAccount.email : email;

    return Auth.resendSignUp(username)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error));
    // await signIn({
    //   email: username,
    //   password: unverifiedAccount?.password,
    // });
  };

  /**
   * logout user
   */
  const signOut = async () => Auth.signOut();

  const getAccessToken = async () => {
    const session = await Auth.currentSession();
    const accessToken = session?.getAccessToken();
    return accessToken?.getJwtToken();
  };

  const value = {
    user,
    isAuthenticated: !!user,
    isAuthenticating,
    unverifiedAccount,
    signIn,
    signOut,
    signUp,
    confirmAccount,
    resendSignupCode,
    getAccessToken,
  };

  if (isAuthenticating) {
    return <LoadingOverlay visible={true} />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default CognitoAuthProvider;
