import styled from "@emotion/styled";

export const LayoutDiv = styled.div`
height: 100vh;
    width: 100vw;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
`

export const MainContainerDiv = styled.div`
    -webkit-box-flex: 1;
    flex-grow: 1;
    background-color: #f8f8f8;
    transform: none;
    transition: transform 0.2s cubic-bezier(0.67, 0.1, 0.27, 0.96) 0s, opacity 0.2s cubic-bezier(0.67, 0.1, 0.27, 0.96) 0s, max-width 0.2s ease-in-out 0s;
    overflow: auto;
    position: relative;
    z-index: 2;
    /* border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; */
    max-width: 100vw;

    > div{
        -webkit-box-flex: 1;
        flex-grow: 1;
        width: 100%;
        position: relative;
        height: 100%;
        z-index: 1;
    }

    .main-inner{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

export const MainContentDiv = styled.div`
    padding: 40px;
    margin: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
`