import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { CognitoAuthProvider } from "./providers/auth-cognito";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID!;
const isProduction = process.env.NODE_ENV === "production";

ReactGA.initialize(GA_ID, {
  testMode: !isProduction,
});

// configure amplify
Amplify.configure({
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: process.env.REACT_APP_USERPOOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID,
});

root.render(
  <React.StrictMode>
    <CognitoAuthProvider>
      <App />
    </CognitoAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
